import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./RoundedBrick.module.scss";

export const RoundedBrickType = {
	Default: require("./assets/roundedBrickDefault.png"),
	User: require("./assets/roundedBrickUser.png"),
};

const RoundedBrick = ({ type, className }) => (
	<div className={classNames(styles.roundedBrick, className)}>
		<img src={type} alt="" className={styles.image} />
	</div>
);

RoundedBrick.propTypes = {
	type: PropTypes.string.isRequired,
};
RoundedBrick.defaultProps = {
	type: RoundedBrickType.Default,
};

export default RoundedBrick;
