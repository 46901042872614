import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Brick.module.scss";

export const BrickColors = {
	LightGrey: "lightgrey",
	Grey: "grey",
	DarkGrey: "darkgrey",
	Orange: "orange",
};

const Brick = ({ width, height, color, rounded, className, ...rest }) => (
	<div
		className={classNames(
			styles.brick,
			styles[`b-${color}`],
			styles[`b-${width}x${height}`],
			{
				[styles.rounded]: rounded,
			},
			className,
		)}
		{...rest}
	/>
);

Brick.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	color: PropTypes.string,
	rounded: PropTypes.bool,
};
Brick.defaultProps = {
	width: 1,
	height: 1,
	color: BrickColors.Grey,
	rounded: false,
};

export default Brick;
