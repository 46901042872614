import React from "react";
import classNames from "classnames";

import { noop } from "constants/helpers";
import styles from "./Notification.module.scss";

export const NotificationType = {
	Default: "default",
	Error: "error",
	Info: "info",
	Success: "success",
	Warn: "warn",
};

const Notification = ({ children, type = NotificationType.Default, onClose = noop }) => (
	<div className={classNames(styles.notification, styles[type])} onClick={onClose}>
		{children}
	</div>
);

export default Notification;
