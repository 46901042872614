import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Label.module.scss";

const Label = ({ children, className, htmlFor }) => (
	<label className={classNames(styles.label, className)} htmlFor={htmlFor}>
		{children}
	</label>
);

Label.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	htmlFor: PropTypes.string,
};

export default Label;
