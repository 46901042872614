import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { vote } from "constants/propShapes";
import config from "constants/config";
import Brick, { BrickColors } from "components/Brick";
import RoundedBrick from "components/RoundedBrick";
import FramedSection from "components/FramedSection";
import { WEEKDAY } from "namespaces";
import { getCurrentDate } from "services/dateService";

import Day from "./components/Day";
import { ReactComponent as NoSvg } from "./assets/no.svg";
import { ReactComponent as YesSvg } from "./assets/yes.svg";

import styles from "./Topic.module.scss";

const currentDate = getCurrentDate(null, config.DefaultDateFormat);

const Topic = ({ topicId, text, teamVotes, userVotes, weekDates, isEditable, showTotals, onClickDeleteVote }) => {
	// @ToDo clean up, refactor Day.js as well, split totals and brick day components

	const [totalVotesDenial, totalVotesApproval] = useMemo(() => {
		const totalVotesDenial = teamVotes.filter(teamVote => teamVote.approval === false).length;
		const totalVotesApproval = teamVotes.length - totalVotesDenial;

		return [totalVotesDenial, totalVotesApproval];
	}, [teamVotes]);

	const renderVotes = ({ date, approval }) => {
		let approvalHasUserVote = false;
		let topicHasUserVote = false;
		let totalVotesDay = 0;
		let bricks;

		if (teamVotes) {
			bricks = teamVotes
				.filter(teamVote => teamVote.date === date && teamVote.approval === approval)
				.map((value, index) => <RoundedBrick key={index} />);
			totalVotesDay = bricks.length;

			if (userVotes.find(vote => vote.date === date)) {
				topicHasUserVote = true;
				if (userVotes.find(vote => vote.approval === approval && vote.date === date)) {
					approvalHasUserVote = true;
				}
			}

			return [bricks, approvalHasUserVote, topicHasUserVote, totalVotesDay];
		}
	};

	const renderDayAreas = (approval, totalVotesApproval) => {
		return weekDates.map((date, index) => {
			const [bricks, approvalHasUserVote, topicHasUserVote, totalVotesDay] = renderVotes({ date, approval });
			const weekDayId = index + 1;
			const voteHeight = (totalVotesDay / totalVotesApproval) * 100;

			const showDropable = isEditable && !approvalHasUserVote && !topicHasUserVote;
			const showDeleteButton = isEditable && approvalHasUserVote && date === currentDate;
			const showDivider = weekDayId < 5;
			const dropableName = `dropable-${topicId}-${date}-${approval}`;
			const onClickDelete = () => onClickDeleteVote(date, topicId);

			const dayProps = {
				showDeleteButton,
				showTotals,
				showDivider,
				showDropable,
				dropProps: {
					dropableName,
					accept: [`${WEEKDAY}-${date}`],
					lastDroppedItem: null,
				},
				dropAreaProps: {
					topicId: topicId,
					date: date,
					approval,
				},
				bricks,
				onClickDelete,
				voteHeight,
				totalVotesDay,
				approval,
			};

			return <Day {...dayProps} key={date} />;
		});
	};

	const dayAreasDenial = renderDayAreas(false, totalVotesDenial);
	const dayAreasApproval = renderDayAreas(true, totalVotesApproval);

	return (
		<div className={classNames(styles.row, { [styles.showTotals]: showTotals })}>
			<FramedSection width={10} height={12} color={BrickColors.DarkGrey} className={styles.no}>
				<NoSvg />
				{showTotals && <div className={styles.totalTopic}>{totalVotesDenial}</div>}
			</FramedSection>
			<div className={styles.daysWrapper}>
				<Brick color={BrickColors.DarkGrey} width={50} height={1} className={styles.brickBorderTop} />
				<div className={styles.days}>{dayAreasDenial}</div>
				<Brick color={BrickColors.DarkGrey} width={50} height={1} className={styles.brickBorderBottom} />
			</div>
			<FramedSection width={16} height={12} color={BrickColors.DarkGrey} className={styles.statement}>
				{text}
			</FramedSection>
			<div className={styles.daysWrapper}>
				<Brick color={BrickColors.DarkGrey} width={50} height={1} className={styles.brickBorderTop} />
				<div className={styles.days}>{dayAreasApproval}</div>
				<Brick color={BrickColors.DarkGrey} width={50} height={1} className={styles.brickBorderBottom} />
			</div>
			<FramedSection width={10} height={12} color={BrickColors.DarkGrey} className={styles.yes}>
				<YesSvg />
				{showTotals && (
					<div className={classNames(styles.totalTopic, styles.totalTopicApproval)}>{totalVotesApproval}</div>
				)}
			</FramedSection>
		</div>
	);
};

Topic.propTypes = {
	topicId: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
	teamVotes: PropTypes.arrayOf(PropTypes.shape(vote)).isRequired,
	userVotes: PropTypes.arrayOf(PropTypes.shape(vote)).isRequired,
	weekDates: PropTypes.arrayOf(PropTypes.string).isRequired,
	isEditable: PropTypes.bool,
	showTotals: PropTypes.bool,
	onClickDeleteVote: PropTypes.func,
};

Topic.defaultProps = {
	teamVotes: [],
	userVotes: [],
	isEditable: true,
	showTotals: false,
};

export default Topic;
