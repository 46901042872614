import React from "react";

import Hint from "components/Hint";
import { ReactComponent as Icon } from "./assets/icon.svg";

import styles from "./WIP.module.scss";

const WIP = () => (
	<div className={styles.wip}>
		<Icon className={styles.icon} />
		<h1>work in progress</h1>
		<Hint className={styles.hint}>
			<span>Mobilegeräte und kleine Auflösugnen werden aktuell noch nicht unterstützt.</span>
			<span>Die Steinchen brauchen etwas mehr Platz - mindestens 1300px Breite.</span>
		</Hint>
	</div>
);

export default WIP;
