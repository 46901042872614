import Button from "components/Button";
import Input from "components/Form/Input";
import { user } from "constants/propShapes";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { email as emailRegexp } from "constants/validationRegExp";
import { getInputErrorMessage } from "services/formService";
import styles from "./UserForm.module.scss";

const { id, isConfirmed, ...userShapeRequiredOnly } = user;

const UserForm = ({ handleFormSubmit, userData, serverValidationErrors }) => {
	const editMode = userData.id !== undefined;

	const { register, errors, getValues, handleSubmit, setError } = useForm({
		defaultValues: userData,
	});

	const firstNameRegister = useMemo(() => {
		return register({ required: { value: true, message: "Bitte gib einen Vornamen ein." } });
	}, [register]);

	const lastNameRegister = useMemo(() => {
		return register({ required: { value: true, message: "Bitte gib einen Nachnamen ein." } });
	}, [register]);

	const emailRegister = useMemo(() => {
		return register({
			required: { value: true, message: "Bitte gib eine E-Mail Adresse ein." },
			pattern: {
				value: emailRegexp,
				message: "Bitte gib eine gültige E-Mail Adresse ein.",
			},
		});
	}, [register]);

	const isAdminRegister = useMemo(() => {
		return register();
	}, [register]);

	const isActiveRegister = useMemo(() => {
		return register();
	}, [register]);

	const handleSubmitButtonClick = () => {
		handleFormSubmit({ ...userData, ...getValues() });
	};

	useEffect(() => {
		setError(serverValidationErrors);
	}, [setError, serverValidationErrors]);

	return (
		<div className={styles.userFromContainer}>
			<form className={styles.userForm} onSubmit={handleSubmit(handleSubmitButtonClick)}>
				<div className={styles.formGroup}>
					<Input
						autoFocus={!editMode}
						name="firstName"
						inputRef={firstNameRegister}
						label="Vorname"
						maxLength="50"
						errorMessage={getInputErrorMessage(errors, "firstName")}
					/>
					<Input
						name="lastName"
						inputRef={lastNameRegister}
						label="Nachname"
						maxLength="50"
						errorMessage={getInputErrorMessage(errors, "lastName")}
					/>
					<Input
						name="email"
						inputRef={emailRegister}
						label="E-Mail"
						errorMessage={getInputErrorMessage(errors, "email")}
					/>
					<div className={styles.checkboxes}>
						<Input type="checkbox" name="isAdmin" inputRef={isAdminRegister} label="Ist Admin" />
						{editMode && (
							<Input type="checkbox" name="isActive" inputRef={isActiveRegister} label="Ist aktiv" />
						)}
					</div>
				</div>

				<Button.Primary type="submit" disabled={Object.keys(errors).length > 0} className={styles.btnSubmit}>
					{!editMode ? "Freund hinzufügen" : "Freund aktualisieren"}
				</Button.Primary>
			</form>
		</div>
	);
};

UserForm.propTypes = {
	handleFormSubmit: PropTypes.func.isRequired,
	userData: PropTypes.shape(userShapeRequiredOnly),
};

UserForm.defaultProps = {
	userData: {
		firstName: "",
		fullName: "",
		lastName: "",
		email: "",
		isAdmin: false,
		isActive: true,
		invitationToken: null,
	},
};

export default UserForm;
