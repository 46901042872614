import React from "react";
import { formatDate } from "services/dateService";

import AppContext from "contexts/AppContext/context";
import CONFIG from "constants/config";

const processVote = userVote => {
	return { ...userVote, date: formatDate(userVote.date, CONFIG.DefaultDateFormat) };
};

class AppProvider extends React.Component {
	state = {
		topics: [],
		teamVotes: [],
		userVotes: [],
	};

	updateTopics = topics => this.setState(state => ({ ...state, topics }));

	updateTeamVotes = (teamVotes) => {
		this.setState(state => ({
			...state,
			teamVotes: teamVotes.map(vote=>processVote(vote)),
		}));
	};

	updateUserVotes = userVotes => {
		this.setState(state => ({
			...state,
			userVotes: userVotes.map(vote => processVote(vote)),
		}));
	};

	addVote = vote => {
		this.setState(state => {
			vote = processVote(vote);
			return {
				...state,
				userVotes: [...state.userVotes, processVote(vote)],
				teamVotes: [...state.teamVotes, processVote(vote)],
			};
		});
	};

	deleteVote = voteId => {
		this.setState(state => ({
			...state,
			userVotes: state.userVotes.filter(vote => vote.id !== voteId),
			teamVotes: state.teamVotes.filter(vote => vote.id !== voteId),
		}));
	};

	render() {
		const { topics, teamVotes, userVotes } = this.state;
		return (
			<AppContext.Provider
				value={{
					topics,
					teamVotes,
					userVotes,
					addVote: this.addVote,
					deleteVote: this.deleteVote,
					updateTeamVotes: this.updateTeamVotes,
					updateTopics: this.updateTopics,
					updateUserVotes: this.updateUserVotes,
				}}
			>
				{this.props.children}
			</AppContext.Provider>
		);
	}
}


export default AppProvider;
