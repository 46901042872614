import React, { useState } from "react";
import { Link } from "react-router-dom";

import appRoutes from "constants/appRoutes";
import Section from "components/Section";
import Button, { ButtonStyles } from "components/Button";
import * as Icon from "components/Icon";
import Brick, { BrickColors } from "components/Brick";
import Input from "components/Form/Input";
import Select from "components/Form/Select";
import ErrorMessage from "components/ErrorMessage";
import SuccessMessage from "components/SuccessMessage";
import FramedSection from "components/FramedSection";
import Minifigure, { Figures } from "components/Minifigure";
import RoundedBrick, { RoundedBrickType } from "components/RoundedBrick";

import styles from "./Sandbox.module.scss";
import { OverlaySlideIn } from "components/Overlay";

const Sandbox = () => {
	const [brickWidth, setBrickWidth] = useState(5);
	const [brickHeight, setBrickHeight] = useState(11);
	const [brickColor, setBrickColor] = useState(BrickColors.LightGrey);

	const [overlayIsVisible, setOverlayVisibility] = useState(false);

	const showOverlay = () => setOverlayVisibility(true);

	return (
		<Section className={styles.sandbox}>
			<Section.Inner className={styles.section}>
				<h1>Sandbox</h1>
			</Section.Inner>

			<Section.Header>
				<h2>Routes</h2>
			</Section.Header>
			<Section.Navbar>
				<ul className={styles.links}>
					{Object.keys(appRoutes).map((route, index) => {
						const url = Object.values(appRoutes)[index](1, 2, 3);
						return (
							<li key={index}>
								<Link to={url}>{route}</Link>
							</li>
						);
					})}
				</ul>
			</Section.Navbar>

			<Section.Header>
				<h2>Buttons</h2>
			</Section.Header>
			<Section.Inner className={styles.section}>
				<div className={styles.buttons}>
					<Button.Primary>Primary</Button.Primary>
					<Button.Primary disabled>Primary (disabled)</Button.Primary>
					<Button.Primary icon={Icon.IconMenu}>Primary with icon</Button.Primary>
					<Button.Primary icon={Icon.IconMenu} buttonStyle={ButtonStyles.Error}>
						Primary with icon error style
					</Button.Primary>
					<Button.Secondary>Secondary</Button.Secondary>
					<Button.Secondary disabled>Secondary (disabled)</Button.Secondary>
					<Button.Secondary icon={Icon.IconMenu}>Secondary with icon</Button.Secondary>
					<Button.Secondary icon={Icon.IconMenu} buttonStyle={ButtonStyles.Error}>
						Secondary with icon error style
					</Button.Secondary>
					<div className={styles.line}>
						{Object.keys(Icon).map((svg, index) => {
							const SvgIcon = Icon[svg];
							return <Button.Icon icon={SvgIcon} key={index} />;
						})}
					</div>
				</div>
			</Section.Inner>

			<Section.Header>
				<h2>Icons</h2>
			</Section.Header>
			<Section.Inner className={styles.section}>
				<div className={styles.icons}>
					{Object.keys(Icon).map((svg, index) => {
						const SvgIcon = Icon[svg];
						return <SvgIcon key={index} />;
					})}
					<Icon.IconEdit />
				</div>
			</Section.Inner>

			<Section.Header>
				<h2>Bricks</h2>
			</Section.Header>
			<Section.Inner className={styles.section}>
				<div className={styles.line}>
					<Input
						onChange={e => setBrickWidth(parseInt(e.target.value, 10))}
						defaultValue={brickWidth}
						label={"brick width"}
						name="brickWidth"
						type="number"
						min={1}
						max={20}
					/>
					<Input
						onChange={e => setBrickHeight(parseInt(e.target.value, 10))}
						defaultValue={brickHeight}
						label={"brick height"}
						name="brickHeight"
						type="number"
						min={1}
						max={20}
					/>
					<Select
						label={"brick color"}
						onChange={e => setBrickColor(e.target.value)}
						options={Object.keys(BrickColors).map((color, index) => ({
							name: BrickColors[color],
							value: BrickColors[color],
						}))}
						name="brickColor"
					/>
				</div>

				<div className={styles.line}>
					<Brick width={brickWidth} height={brickHeight} color={brickColor} />
					{/* @TODO fix FramedSection */}
					<FramedSection width={10} height={10} className={styles.framedSection} color={brickColor}>
						10x10
					</FramedSection>
					<RoundedBrick type={RoundedBrickType.Default} />
					<RoundedBrick type={RoundedBrickType.User} />
				</div>
			</Section.Inner>

			<Section.Header>
				<h2>Form elements</h2>
			</Section.Header>
			<Section.Inner className={styles.section}>
				<div className={styles.line}>
					<Input name="input-1" />
					<Input name="input-2" label="with label" />
				</div>
				<div className={styles.line}>
					<Select name="select-1" options={[{ name: "name", value: "value" }]} />
					<Select name="select-2" label="with label" options={[{ name: "name", value: "value" }]} />
				</div>
				<div className={styles.line}>
					<SuccessMessage>Success message</SuccessMessage>
					<ErrorMessage>Error message</ErrorMessage>
				</div>
			</Section.Inner>

			<Section.Header>
				<h2>Minifigures</h2>
			</Section.Header>
			<Section.Inner className={styles.section}>
				<div className={styles.line}>
					{Object.keys(Figures).map((figure, index) => (
						<Minifigure
							className={styles.minifigure}
							figure={Figures[figure]}
							data={{ name: `minifigure ${index}` }}
							showInfo
							key={index}
						/>
					))}
				</div>
			</Section.Inner>

			<Section.Header>
				<h2>Stuff</h2>
			</Section.Header>
			<Section.Inner className={styles.section}>
				<Button.Primary onClick={showOverlay}>Show overlay</Button.Primary>
				{overlayIsVisible && (
					<OverlaySlideIn onSlideOut={() => setOverlayVisibility(false)}>
						{({ close }) => (
							<div style={{ padding: 20, background: "blue" }}>
								overlay content <div onClick={close}>click here to close</div>
							</div>
						)}
					</OverlaySlideIn>
				)}
			</Section.Inner>
		</Section>
	);
};

export default Sandbox;
