import React, { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import Button from "components/Button";
import Input from "components/Form/Input";
import { email as emailPattern, password as passwordPattern } from "constants/validationRegExp";
import { getInputErrorMessage } from "services/formService";

import styles from "./Form.module.scss";
import Hint from "components/Hint";

const Form = ({ handleFormSubmit, serverValidationErrors }) => {
	const { register, errors, getValues, handleSubmit, setError, watch } = useForm();
	const password = useRef({});
	password.current = watch("password", "");

	const emailRegister = useMemo(() => {
		return register({
			required: { value: true, message: "Bitte gib eine E-Mail Adresse ein." },
			pattern: {
				value: emailPattern,
				message: "Bitte gib eine gültige E-Mail Adresse ein.",
			},
		});
	}, [register]);

	const passwordRegister = useMemo(() => {
		return register({
			required: { value: true, message: "Bitte gib ein Passwort ein." },
			minLength: { value: 6, message: "Das Passwort muss mindestens 6 Zeichen lang sein." },
			pattern: {
				value: passwordPattern,
				message: "Das Passwort muss mindestens eine Zahl und einen Buchstaben enthalten.",
			},
		});
	}, [register]);

	const passwordConfirmationRegister = useMemo(() => {
		return register({
			required: { value: true, message: "Bitte wiederhole das Passwort." },
			validate: value => value === password.current || "Die Passwörter müssen übereinstimmen",
		});
	}, [register]);

	const handleSubmitButtonClick = () => {
		handleFormSubmit(getValues());
	};

	useEffect(() => {
		setError(serverValidationErrors);
	}, [setError, serverValidationErrors]);

	return (
		<form className={styles.form} onSubmit={handleSubmit(handleSubmitButtonClick)}>
			<div className={styles.formGroup}>
				<Input
					autoFocus
					name="email"
					inputRef={emailRegister}
					label="E-Mail"
					placeholder="Deine E-Mail Adresse"
					errorMessage={getInputErrorMessage(errors, "email")}
				/>

				<Input
					name="password"
					type="password"
					inputRef={passwordRegister}
					label="Passwort"
					maxLength="50"
					errorMessage={getInputErrorMessage(errors, "password")}
				/>

				<Input
					name="passwordConfirmation"
					type="password"
					inputRef={passwordConfirmationRegister}
					label="Passwort wiederholen"
					maxLength="50"
					errorMessage={getInputErrorMessage(errors, "passwordConfirmation")}
				/>
			</div>
			<Hint>Das Passwort muss mindestens 6 Zeichen lang sein, eine Zahl und einen Buchstaben enthalten.</Hint>
			<div className={styles.formGroup}>
				<Button.Primary type="submit" disabled={Object.keys(errors).length > 0}>
					Password speichern
				</Button.Primary>
			</div>
		</form>
	);
};

Form.propTypes = {
	handleFormSubmit: PropTypes.func.isRequired,
	serverValidationErrors: PropTypes.array,
};

export default Form;
