import Loading from "components/Loading";
import { topic, vote } from "constants/propShapes";
import React from "react";
import PropTypes from "prop-types";

import Topic from "components/Topic";
import Weekdays from "components/Weekdays";

import styles from "./VoteBoard.module.scss";

const VoteBoard = ({
	weekDates,
	topics,
	teamVotes,
	userVotes,
	isEditable,
	onClickDeleteVote,
	showTotals,
	isLoading,
}) => (
	<div className={styles.voteBoardWrapper}>
		<div className={styles.topicsHead}>
			<Weekdays className={styles.weekdays} />
			<Weekdays className={styles.weekdays} />
		</div>
		{topics.map((data, index) => (
			<Topic
				topicId={data.id}
				text={data.text}
				teamVotes={teamVotes.filter(vote => vote.topicId === data.id)}
				userVotes={userVotes.filter(vote => vote.topicId === data.id)}
				weekDates={weekDates}
				isEditable={isEditable}
				onClickDeleteVote={onClickDeleteVote}
				key={index}
				showTotals={showTotals}
			/>
		))}
		{isLoading && <Loading layered />}
	</div>
);

VoteBoard.propTypes = {
	weekDates: PropTypes.array.isRequired,
	topics: PropTypes.arrayOf(PropTypes.shape(topic)).isRequired,
	teamVotes: PropTypes.arrayOf(PropTypes.shape(vote)).isRequired,
	userVotes: PropTypes.arrayOf(PropTypes.shape(vote)).isRequired,
	isEditable: PropTypes.bool,
	onClickDeleteVote: PropTypes.func,
	showTotals: PropTypes.bool,
	isLoading: PropTypes.bool,
};
VoteBoard.defaultProps = {
	teamVotes: [],
	userVotes: [],
	isEditable: true,
	showTotals: false,
	isLoading: false,
};

export default VoteBoard;
