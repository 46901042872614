// export keys from process.env (.env files are autoloaded by create-react-app)
const env = {};

// translate "REACT_APP_VARNAME" to "VARNAME"
Object.keys(process.env).forEach(key => {
	const match = key.match(/(?:^REACT_APP_)?(.*)/);
	const myKey = match[1];
	let myValue = process.env[key];
	if (typeof myValue === "string") {
		myValue = myValue.replace(/%(.+?)%/g, (match, group) => match);
		// convert boolean-ish values to true bool
		if (String(myValue).toLowerCase() === "true") myValue = true;
		if (String(myValue).toLowerCase() === "false") myValue = false;
	}
	env[myKey] = myValue;
});

// ugly workaround: use CommonJS to export all keys
module.exports = env;
