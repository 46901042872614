import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";

import AppRoutes from "constants/appRoutes";
import { DEBUG } from "constants/env";
import AppProvider from "contexts/AppContext/provider";
import Header from "components/Header";
import Main from "views/Home/Main";
import Footer from "components/Footer";
import DebugTools from "components/DebugTools";
import { AuthContext } from "contexts/AuthContext";
import Admin from "views/Admin";
import Login from "views/Login";
import Sandbox from "views/Sandbox";
import SetPassword from "views/SetPassword";
import Success from "views/SetPassword/Success";
import ProtectedRoute from "components/ProtectedRoute";
import { UsersContextProvider } from "contexts/Users";

const AppRouter = () => {
	const { user } = useContext(AuthContext);

	return (
		<Switch>
			<Route exact path={AppRoutes.login()} component={Login} />
			<Route exact path={AppRoutes.setPasswordSuccess()} component={Success} />
			<Route exact path={AppRoutes.setPassword(":token")} component={SetPassword} />
			<AppProvider>
				<UsersContextProvider>
					<Header />
					<ProtectedRoute exact path={AppRoutes.home()} component={Main} />
					{user && user.isAdmin && <Admin />}
					{DEBUG && (
						<>
							<Route exact path={AppRoutes.sandbox()} component={Sandbox} />
							<DebugTools />
						</>
					)}
					<Footer />
				</UsersContextProvider>
			</AppProvider>
		</Switch>
	);
};

export default AppRouter;
