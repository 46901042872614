import React from "react";
import classNames from "classnames";

import { IconAvatar } from "components/Icon";
import styles from "./Hint.module.scss";

const Hint = ({ children, className }) => (
	<div className={classNames(styles.hint, className)}>
		<IconAvatar className={styles.iconAvatar} />
		<div className={styles.children}>{children}</div>
	</div>
);

export default Hint;
