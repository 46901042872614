import axiosApi from "axiosApi";

const ACCESS_TOKEN_KEY = "token";
const REFRESH_TOKEN_KEY = "refresh_token";


export const getAccessToken = () => {
	return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
	return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const deleteTokens = ()=>{
	axiosApi.defaults.headers.common["Authorization"] = "";
	localStorage.removeItem(ACCESS_TOKEN_KEY);
	localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const setTokens = (tokenObject) => {
    axiosApi.defaults.headers.common["Authorization"] = "Bearer " + tokenObject.access_token;
	localStorage.setItem(ACCESS_TOKEN_KEY, tokenObject.access_token);
	localStorage.setItem(REFRESH_TOKEN_KEY, tokenObject.refresh_token);
};
