import React, { useEffect, useState } from "react";
import axiosApi from "axiosApi";
import ApiRoutes from "constants/apiRoutes";
import { deleteTokens, setTokens } from "services/tokenService";

import { AuthContext } from "./AuthContext";

export const AuthContextProvider = ({ accessToken, children }) => {
	const [user, setUser] = useState();
	const [authToken, setAuthToken] = useState(accessToken);

	const signIn = async (email, password) => {
		try {
			const loginResponse = await axiosApi.post(ApiRoutes.login, { email: email, password: password });
			setTokens(loginResponse.data);
			setAuthToken(loginResponse.data.access_token);
			return loginResponse.data.accessToken;
		} catch (error) {
			throw new Error("Login failed");
		}
	};

	const signOut = async () => {
		try {
			await axiosApi.delete(ApiRoutes.logout);
		} catch (error) {}
		setAuthToken();
		setUser();
		deleteTokens();
	};

	useEffect(() => {
		const getAuthenticatedUser = async () => {
			try {
				const userResponse = await axiosApi.get(ApiRoutes.getAuthenticatedUser);
				setUser(userResponse.data);
			} catch (error) {
				setAuthToken();
				deleteTokens();
				console.log(error);
			}
		};

		if (authToken) {
			getAuthenticatedUser();
		}
	}, [authToken]);

	return (
		<AuthContext.Provider
			value={{
				user,
				authToken,
				setAuthToken,
				signIn,
				signOut,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
