import React, { useEffect } from "react";
import classNames from "classnames";
import { useClipboard } from "use-clipboard-copy";

import Button from "components/Button";
import { IconCheck, IconInvitation } from "components/Icon";
import { showNotification, NotificationType } from "components/Notification";

import styles from "./CopyLink.module.scss";

const CopyLink = ({ link }) => {
	console.log(link);
	const clipboard = useClipboard({ copiedTimeout: 1000 });

	useEffect(() => {
		if (clipboard.copied) {
			showNotification({
				message: "Link wurde in die Zwischenablage kopiert",
				type: NotificationType.Success,
			});
		}
	}, [clipboard]);

	const onClick = () => {
		clipboard.copy(link);
	};

	return (
		<div className={styles.copyLink}>
			<Button.Primary onClick={onClick} icon={clipboard.copied ? IconCheck : IconInvitation}>
				Link kopieren
			</Button.Primary>
			<div
				className={classNames(styles.invitationLink, {
					[styles.copied]: clipboard.copied,
				})}
				onClick={onClick}
			>
				{link}
			</div>
		</div>
	);
};

export default CopyLink;
