import React from "react";
import PropTypes from "prop-types";
import { useDrag } from "react-dnd";

const style = {
	cursor: "move",
};

const DraggableComponent = ({ name, type, topicId, className, children, onDropped }) => {
	const [{ isDragging }, drag] = useDrag({
		item: { name, type, topicId },
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult();
			if (item && dropResult && onDropped) {
				onDropped.call(this, { item, dropResult });
				//alert(`You dropped ${item.name} into ${dropResult.name}!`);
			}
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});
	const opacity = isDragging ? 0.4 : 1;
	return (
		<div ref={drag} className={className} style={{ ...style, opacity }}>
			{children}
		</div>
	);
};

DraggableComponent.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	topicId: PropTypes.number.isRequired,
	className: PropTypes.string,
	children: PropTypes.node,
	onDropped: PropTypes.func,
};

export default DraggableComponent;
