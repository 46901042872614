import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./ErrorMessage.module.scss";

const ErrorMessage = ({ className, children }) => (
	<div className={classNames(styles.errorMsg, className)}>{children}</div>
);

ErrorMessage.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

export default ErrorMessage;
