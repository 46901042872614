import React, { useMemo, useCallback } from "react";
import { Transition, animated } from "react-spring/renderprops";
import classNames from "classnames";

import styles from "./SlideIn.module.scss";

export const SlideInDirection = {
	BottomTop: "BottomTop",
	TopBottom: "TopBottom",
	LeftRight: "LeftRight",
	RightLeft: "RightLeft",
};

const startPosition = new Map([
	[SlideInDirection.BottomTop, "0,100%"],
	[SlideInDirection.TopBottom, "0, -100%"],
	[SlideInDirection.LeftRight, "-100%,0"],
	[SlideInDirection.RightLeft, "100%,0"],
]);

const getTransitionStates = direction => {
	const startTransform = `translate3d(${startPosition.get(direction)},0)`;
	return {
		from: { transform: startTransform },
		enter: { transform: "translate3d(0%,0,0)" },
		leave: { transform: startTransform },
	};
};

export const SlideIn = ({
	children,
	active = true,
	onSlideOut,
	direction = SlideInDirection.BottomTop,
	duration = 250,
}) => {
	const onDestroyed = useCallback(() => setTimeout(onSlideOut, 0), [onSlideOut]);
	const transitionStates = useMemo(() => getTransitionStates(direction), [direction]);
	return (
		<Transition native items={active} onDestroyed={onDestroyed} config={{ duration }} {...transitionStates}>
			{show =>
				show &&
				(props => (
					<animated.div className={classNames(styles.slide, styles[direction])} style={props}>
						{children}
					</animated.div>
				))
			}
		</Transition>
	);
};

export default SlideIn;
