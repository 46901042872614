import React, { useState, useEffect, useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import Button from "components/Button";
import Input from "components/Form/Input";
import { DEBUG, DEV_EMAIL, DEV_PASSWORD } from "constants/env";
import appRoutes from "constants/appRoutes";

import { ReactComponent as LoadingSVG } from "assets/icons/loading.svg";
import { ReactComponent as Logo } from "assets/images/logo-speechbubble.svg";
import { getInputErrorMessage } from "services/formService";

import styles from "./Login.module.scss";
import Minifigure, { Figures } from "components/Minifigure";
import ErrorMessage from "components/ErrorMessage";
import { AuthContext } from "contexts/AuthContext";
import { email as emailPattern } from "constants/validationRegExp";

const Login = () => {
	let defaultValues = DEBUG ? { email: DEV_EMAIL, password: DEV_PASSWORD } : { email: null, password: null };

	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { signIn } = useContext(AuthContext);
	const [loginError, setLoginError] = useState(false);
	const { register, errors, handleSubmit, watch } = useForm({ defaultValues: defaultValues });

	const { email, password } = watch();
	const emailRegister = useMemo(() => {
		return register({
			required: { value: true, message: "Bitte gib eine E-Mail Adresse ein." },
			pattern: {
				value: emailPattern,
				message: "Bitte gib eine gültige E-Mail Adresse ein.",
			},
		});
	}, [register]);

	const passwordRegister = useMemo(() => {
		return register({
			required: { value: true, message: "Bitte gib ein Passwort ein." },
		});
	}, [register]);

	useEffect(() => {
		setLoginError(false);
	}, [email, password]);

	const handleFormSubmit = formData => {
		setLoading(true);
		setLoginError(false);
		signIn(formData.email, formData.password)
			.then(() => {
				history.push(appRoutes.home());
			})
			.catch(error => {
				console.warn(error);
				setLoginError(true);
				setLoading(false);
			});
	};

	return (
		<div className={styles.login}>
			<Logo className={classNames(styles.logo, { [styles.hidden]: loading })} />
			<form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
				<Input
					autoFocus
					name="email"
					label="E-Mail"
					placeholder="superhero@freundedeshaues.eu"
					inputRef={emailRegister}
					className={styles.input}
					disabled={loading}
					errorMessage={getInputErrorMessage(errors, "email")}
				/>

				<Input
					name="password"
					type="password"
					label="Passwort"
					maxLength="50"
					placeholder="*******"
					inputRef={passwordRegister}
					className={styles.input}
					disabled={loading}
					errorMessage={getInputErrorMessage(errors, "password")}
				/>

				{loginError && (
					<ErrorMessage className={styles.errorMsg}>Uups, da ist was schief gelaufen</ErrorMessage>
				)}

				<Button.Primary className={styles.btn}>
					{loading ? <LoadingSVG className={styles.loadingSpinner} /> : "Einloggen"}
				</Button.Primary>

				<div className={classNames(styles.minifigures, { [styles.hidden]: loading })}>
					<Minifigure figure={Figures.Female} className={styles.figureLeft} />
					<Minifigure figure={Figures.Male} className={styles.figureRight} />
				</div>
			</form>
		</div>
	);
};

export default Login;
