import { API_URL } from "constants/env";

export default {
	baseUrl: API_URL,
	login: "clients/web/user/login",
	refreshToken: "clients/web/user/refresh",
	logout: "logout",
	createInvitation: userId => `user/${userId}/invitation`,
	createUser: "user",
	createVote: "vote",
	deleteInvitation: userId => `user/${userId}/invitation`,
	deleteVote: "vote",
	getAuthenticatedUser: "user/profile",
	getAllTopics: "topics",
	getInitialVote: "vote/initial",
	getVotesByDate: "votes",
	getUserVotesByDay: "votes/user",
	getUsers: "users",
	getUser: "user",
	setPassword: "user/password",
	updateUser: "user",
	validateInvitationToken: "user/invitation/validate",
};
