import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

import styles from "./Modal.module.scss";

const modalRoot = document.body;

export const Modal = ({ children }) => {
	const modalContainer = useMemo(() => {
		const elem = document.createElement("div");
		elem.className = styles.modal;
		return elem;
	}, []);
	useEffect(() => {
		modalRoot.appendChild(modalContainer);
		return () => void modalRoot.removeChild(modalContainer);
	}, [modalContainer]);
	return createPortal(children, modalContainer);
};

export default Modal;
