import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Section.module.scss";

const SectionContext = React.createContext();
const defaultPropTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

const Header = ({ children, className }) => <div className={classNames(styles.header, className)}>{children}</div>;
const Inner = ({ children, className }) => <div className={classNames(styles.inner, className)}>{children}</div>;
const Navbar = ({ children, className }) => <div className={classNames(styles.navbar, className)}>{children}</div>;

const Section = ({ children, className }) => (
	<section className={classNames(styles.section, className)}>
		<SectionContext.Provider>{children}</SectionContext.Provider>
	</section>
);

Section.Header = Header;
Section.Inner = Inner;
Section.Navbar = Navbar;

Header.propTypes = defaultPropTypes;
Inner.propTypes = defaultPropTypes;
Navbar.propTypes = defaultPropTypes;
Section.propTypes = defaultPropTypes;

export default Section;
