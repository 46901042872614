import RoundedBrick from "components/RoundedBrick";
import React from "react";
import classNames from "classnames";

import Dropable from "components/Dropable";
import Brick, { BrickColors } from "components/Brick";

import styles from "./Day.module.scss";
import Button, { ButtonStyles } from "components/Button";
import { IconDelete } from "components/Icon";

const Day = ({
	showDeleteButton,
	showTotals,
	showDivider,
	showDropable,
	dropProps,
	dropAreaProps,
	bricks,
	onClickDelete,
	voteHeight,
	totalVotesDay,
	approval,
}) => {
	const { dropableName, accept, lastDroppedItem } = dropProps;

	return (
		<div className={classNames(styles.day, { [styles.showTotals]: showTotals })}>
			<div className={styles.dropAreaWrapper}>
				<div className={styles.votes}>{!showTotals && bricks}</div>
				{showDropable && (
					<Dropable
						name={dropableName}
						className={styles.dropArea}
						dropProps={{ accept, lastDroppedItem }}
						dropAreaProps={dropAreaProps}
					/>
				)}

				{showDeleteButton && (
					<div className={styles.buttonWrapper}>
						<RoundedBrick className={styles.userBrick}/>
						<Button.Icon icon={IconDelete} onClick={onClickDelete} buttonStyle={ButtonStyles.Error} className={styles.deleteButton} />
					</div>
				)}
			</div>
			{showTotals && (
				<div className={styles.totalDay}>
					<div
						className={classNames(styles.bar, { [styles.barApproval]: approval })}
						style={{ height: `${voteHeight}%` }}
					/>
					{totalVotesDay}
				</div>
			)}

			<Brick color={BrickColors.LightGrey} width={1} height={10} className={styles.divider} />
		</div>
	);
};

export default Day;
