const adminRoute = "/admin";
const admin = {
	admin: () => adminRoute,
	adminReports: (date) => `${adminRoute}/reports/${date}`,
	adminUsers: () => `${adminRoute}/users`,
	createUser: () => `${adminRoute}/users/create`,
	editUser: id => `${adminRoute}/users/${id}/edit`,
};

export default {
	home: () => "/",
	sandbox: () => "/sandbox",
	login: () => "/login",
	setPassword: token => `/user/setpassword/${token}`,
	setPasswordSuccess: () => "/user/setpassword/success",
	...admin,
};
