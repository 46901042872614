import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Tile.module.scss";

const Tile = ({ children, className }) => (
	<div className={classNames(styles.tile, className)}>
		<div className={styles.inner}>{children}</div>
	</div>
);

Tile.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

export default Tile;
