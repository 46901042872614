import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Label from "components/Form/Label";
import ErrorMessage from "components/ErrorMessage";

import styles from "./Input.module.scss";

const Input = ({ type, name, className, label, inputRef, errorMessage, icon, ...rest }) => {
	const errorClass = errorMessage ? styles.error : null;
	const Icon = icon || null;

	return (
		<div className={classNames(styles.inputWrapper, styles[`type-${type}`], className)}>
			<div>
				{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
				<input
					className={classNames(styles.input, errorClass, { [styles.hasIcon]: icon })}
					type={type}
					name={name}
					id={name}
					{...rest}
					ref={inputRef}
				/>
				<div>
					{icon && <Icon className={styles.icon} />}
					{label && (
						<Label className={styles.label} htmlFor={name}>
							{label}
						</Label>
					)}
				</div>
			</div>
		</div>
	);
};

Input.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	label: PropTypes.string,
	errorMessage: PropTypes.string,
	// add icon here
	// add inputRef here
};
Input.defaultProps = {
	type: "text",
};

export default Input;
