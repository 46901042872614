import React from "react";
import classNames from "classnames";

import styles from "./Weekdays.module.scss";

const days = ["Mo", "Di", "Mi", "Do", "Fr"];
const Weekdays = ({ className = "" }) => (
	<div className={classNames(styles.weekdays, className)}>
		{days.map((day, index) => (
			<div key={index}>{day}</div>
		))}
	</div>
);

export default Weekdays;
