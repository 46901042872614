import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Label from "components/Form/Label";
import ErrorMessage from "components/ErrorMessage";

import { ReactComponent as SvgArrow } from "./selectArrow.svg";

import styles from "./Select.module.scss";

const Select = ({ options, name, className, label, selectRef, errorMessage, ...rest }) => {
	const errorClass = errorMessage ? styles.error : null;

	return (
		<div className={classNames(styles.selectWrapper, className)}>
			<div>
				{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
				<select
					className={classNames(styles.select, errorClass)}
					name={name}
					id={name}
					{...rest}
					ref={selectRef}
				>
					{options.map((option, index) => (
						<option value={option.value} key={index}>
							{option.name}
						</option>
					))}
				</select>
				<div>
					{label && (
						<Label className={styles.label} htmlFor={name}>
							{label}
						</Label>
					)}
				</div>
				<SvgArrow className={styles.arrow} />
			</div>
		</div>
	);
};

Select.propTypes = {
	options: PropTypes.array.isRequired,
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	label: PropTypes.string,
	errorMessage: PropTypes.string,
	// add icon here
	// add selectRef here
};

export default Select;
