import Loading from "components/Loading";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import DraggableBricks from "components/DraggableBricks";
import VoteBoard from "components/VoteBoard";
import Section from "components/Section";
import AppContext from "contexts/AppContext/context";
import { AuthContext } from "contexts/AuthContext";
import CONFIG from "constants/config";
import { createVote, loadTeamVotes, loadTopics, loadUserVotes, deleteVote as deleteVoteApi } from "services/apiLoaders";
import { formatDate, getCurrentDate, getStartOfWeekDate } from "services/dateService";
import { errors } from "constants/texts";
import { showNotification, NotificationType } from "components/Notification";

import "toasted-notes/src/styles.css";
import styles from "./Main.module.scss";

const Main = () => {
	const {
		topics,
		userVotes,
		teamVotes,
		updateTopics,
		updateTeamVotes,
		updateUserVotes,
		addVote,
		deleteVote,
	} = useContext(AppContext);
	const { user: authenticatedUser } = useContext(AuthContext);

	const [loading, setLoading] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [bricksUsed, setBricksUsed] = useState(0);
	const startOfWeek = useMemo(() => getStartOfWeekDate(), []);
	const weekDates = useMemo(() => {
		if (startOfWeek.add) {
			return [...Array(5)].map((value, index) => {
				return startOfWeek.add(index, "days").format(CONFIG.DefaultDateFormat);
			});
		}
		return [];
	}, [startOfWeek]);

	// Background refresh
	const backgroundRefreshRef = useRef();
	const isLoadingRef = useRef();

	const refreshTeamVotes = useCallback(() => {
		backgroundRefreshRef.current = setTimeout(async () => {
			try {
				if (!isLoadingRef.current && !document.hidden) {
					setIsRefreshing(true);
					const teamVotesResponse = await loadTeamVotes(formatDate(startOfWeek, CONFIG.DefaultDateFormat));
					updateTeamVotes(teamVotesResponse.data);
					setIsRefreshing(false);
				}
			} catch (error) {
				console.warn(error);
				showNotification({
					message: "refresh error",
					type: NotificationType.Error,
				});
				setIsRefreshing(false);
			}
			refreshTeamVotes();
		}, CONFIG.backgroundRefreshTimeout);
	}, [startOfWeek, updateTeamVotes]);

	useEffect(() => {
		refreshTeamVotes();

		return () => {
			clearInterval(backgroundRefreshRef.current);
		};
	}, [refreshTeamVotes]);

	// because we want to check the loading state in the setTimeout callback, we additionally need to store it a ref
	// trying to access the state directly would only reflect the value at the time the timeout was set
	useEffect(() => {
		isLoadingRef.current = loading;
	}, [loading]);

	// END OF Background refresh

	// load initial data
	useEffect(() => {
		(async () => {
			try {
				const [userVotesResponse, teamVotesResponse] = await Promise.all([
					loadUserVotes(getCurrentDate(null, CONFIG.DefaultDateFormat)),
					loadTeamVotes(formatDate(startOfWeek, CONFIG.DefaultDateFormat)),
				]);

				updateUserVotes(userVotesResponse.data);
				updateTeamVotes(teamVotesResponse.data);

				setLoading(false);
				setBricksUsed(userVotesResponse.data.length);
			} catch (error) {
				console.warn(error);
				showNotification({
					message: errors.defaultErrorMessage(),
					type: NotificationType.Error,
				});
			}
		})();
	}, [updateTeamVotes, updateUserVotes, startOfWeek]);

	useEffect(() => {
		(async () => {
			try {
				const topicsResponse = await loadTopics();
				updateTopics(topicsResponse.data);
				setInitialLoading(false);
			} catch (error) {
				console.warn(error);
				showNotification({
					message: errors.defaultErrorMessage(),
					type: NotificationType.Error,
				});
			}
		})();
	}, [updateTopics]);

	const onBrickDrop = useCallback(
		async ({ item, dropResult }) => {
			setLoading(true);
			setBricksUsed(userVotes.length + 1);
			const { topicId, approval, date } = dropResult;

			addVote({
				id: 0,
				userId: authenticatedUser.id,
				topicId: topicId,
				approval: approval,
				date: date,
			});
			try {
				const newVoteResponse = await createVote(topicId, approval === true ? 1 : 0);
				deleteVote(0);
				addVote(newVoteResponse.data);
			} catch (error) {
				// @ToDo Handle errors // fetch and add Vote if it already exists
				console.warn(error);
				deleteVote(0);
				setBricksUsed(userVotes.length);
				showNotification({
					message: errors.defaultErrorMessage(),
					type: NotificationType.Error,
				});
			}
			setLoading(false);
		},
		[addVote, deleteVote, userVotes.length, authenticatedUser],
	);

	const onClickDeleteVote = useCallback(
		async (date, topicId) => {
			setLoading(true);
			const vote = userVotes.find(vote => vote.date === date && vote.topicId === topicId);

			try {
				await deleteVoteApi(vote.id);
				setBricksUsed(userVotes.length - 1);
				deleteVote(vote.id);
			} catch (error) {
				// @ToDo Handle errors // remove vote from provider if it does not exist in db anymore
				console.warn(error);
				setBricksUsed(userVotes.length);
				showNotification({
					message: "delete error",
					type: NotificationType.Error,
				});
			}

			setLoading(false);
		},
		[userVotes, deleteVote],
	);

	// @ToDo @Stefan: Refresh failed warning bauen
	return (
		<Section className={styles.main}>
			<Section.Inner>
				<div className={styles.head}>
					<DraggableBricks onDrop={onBrickDrop} bricksUsed={bricksUsed} isLoading={initialLoading} />
					{(isRefreshing || loading) && <Loading className={styles.loading} />}
				</div>
				<VoteBoard
					weekDates={weekDates}
					topics={topics}
					teamVotes={teamVotes}
					userVotes={userVotes}
					onClickDeleteVote={onClickDeleteVote}
					isLoading={initialLoading}
				/>
			</Section.Inner>
		</Section>
	);
};

export default Main;
