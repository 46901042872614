export const errors = {
	defaultErrorMessage: () => "Es ist ein Fehler aufgetreten.",
	votes: {
		noVotesAvailable: () => "Für den ausgewählten Zeitraum gibt es keine Daten.",
	},
};

export const welcomeUser = () => {
	const greetings = [
		"Willkommen",
		"Aloha",
		"Konnichiwa",
		"Hallo",
		"Namaste",
		"Ciao",
		"Moin",
		"Moin Moin",
		"Moinsen",
		"Moini",
		"Servus",
		"Hello",
		"Grüzi",
		"Tach",
		"Hola",
		"Hoi",
		"Hi",
		"Hej",
		"Ullaaa",
	];
	return greetings[Math.floor(Math.random() * greetings.length)];
};

export default {
	errors: { ...errors },
};
