import React, { useState, useCallback } from "react";

import { Overlay, BgBlurLevel } from "./Overlay";
import { SlideIn } from "./SlideIn";

export const OverlaySlideIn = ({ children: render, onSlideOut, ...slideInProps }) => {
	const [active, setActive] = useState(true);
	const close = useCallback(() => setActive(false), []);

	const handleSlideOut = useCallback(() => {
		close();
		onSlideOut();
	}, [close, onSlideOut]);

	return (
		<Overlay bgBlur={active ? BgBlurLevel.Normal : BgBlurLevel.None} onBgClick={close}>
			<SlideIn {...slideInProps} onSlideOut={handleSlideOut} active={active}>
				{render({ close })}
			</SlideIn>
		</Overlay>
	);
};

export default OverlaySlideIn;
