import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import classNames from "classnames";

import { setPassword, validateInvitationToken } from "services/apiLoaders";
import appRoutes from "constants/appRoutes";
import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";
import Section from "components/Section";
import Form from "views/SetPassword/Form";
import Title from "components/Title";
import Hint from "components/Hint";
import { IconWarning } from "components/Icon";

import styles from "./SetPassword.module.scss";

const SetPassword = () => {
	const { token } = useParams();
	const history = useHistory();
	const [tokenIsValid, setTokenIsValid] = useState();
	const [validatingToken, setValidatingToken] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const [submissionError, setSubmissionError] = useState();
	const [serverValidationErrors, setServerValidationErrors] = useState([]);

	const handleFormSubmit = async formData => {
		setSubmitting(true);
		formData = { ...formData, token: token };
		try {
			await setPassword(formData);
			history.push(appRoutes.setPasswordSuccess());
		} catch (error) {
			const response = error.response;
			if (response.status === 422 && response.data.errors) {
				if (response.data.errors.email) {
					setServerValidationErrors([
						{
							type: "invalid",
							name: "email",
							message: "Diese E-Mail Adresse existiert nicht in unserem System.",
						},
					]);
				} else if (response.data.errors.token) {
					setServerValidationErrors([
						{
							type: "invalid",
							name: "email",
							message: "Diese E-Mail Adresse gehört nicht zu diesem Einladungslink.",
						},
					]);
				}
			} else {
				setSubmissionError(
					"Deine Anfrage konnte nicht verarbeitet werden. Bitte versuche es später noch einmal.",
				);
			}

			setSubmitting(false);
		}
	};

	useEffect(() => {
		const validateToken = async token => {
			try {
				const tokenResponse = await validateInvitationToken(token);
				setTokenIsValid(tokenResponse.data.tokenIsValid);
			} catch (error) {
				console.log(error);
			}
			setValidatingToken(false);
		};
		if (token) {
			validateToken(token);
		}
	}, [token]);

	if (validatingToken) return <Loading layered />;

	return (
		<>
			<Section className={styles.setPassword}>
				<Section.Inner className={styles.inner}>
					<Title className={styles.title} />

					{token && !validatingToken && !tokenIsValid ? (
						<div className={classNames(styles.box, styles.invalidToken)}>
							<IconWarning className={styles.iconWarning} />
							<ErrorMessage>Dieser Link ist leider nicht mehr gültig.</ErrorMessage>
							<Hint>
								Begib dich direkt zum Checkout-Admin, gehe nicht über Los und ziehe keine 4.000€ ein.
							</Hint>
						</div>
					) : (
						<div className={styles.box}>
							<h1>Passwort festlegen</h1>
							{submissionError && <ErrorMessage>{submissionError}</ErrorMessage>}

							{token && tokenIsValid && (
								<Form
									handleFormSubmit={handleFormSubmit}
									serverValidationErrors={serverValidationErrors}
								/>
							)}

							{submitting && <Loading layered />}
						</div>
					)}
				</Section.Inner>
			</Section>
		</>
	);
};

SetPassword.propTypes = {};

export default SetPassword;
