import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ReactComponent as MaleSvg } from "./assets/male.svg";
import { ReactComponent as FemaleSvg } from "./assets/female.svg";
import styles from "./Minifigure.module.scss";

export const Figures = {
	Male: {
		normal: MaleSvg,
	},
	Female: {
		normal: FemaleSvg,
	},
	Manni: {
		normal: require("./assets/manni.png"),
		hover: require("./assets/manni-hover.png"),
	},
	Paul: {
		normal: require("./assets/paul.png"),
		hover: require("./assets/paul-hover.png"),
	},
	Stefan: {
		normal: require("./assets/stefan.png"),
		hover: require("./assets/stefan-hover.png"),
	},
};

const InfoBox = ({ data }) => (
	<div className={styles.infobox}>
		{Object.keys(data).map((key, i) => (
			<div key={i}>
				{key}: {data[key]}
			</div>
		))}
	</div>
);

const Minifigure = ({ figure, data, className, showInfo }) => {
	const [isActive, setActive] = useState(false);

	const onMouseEnter = () => setActive(true);
	const onMouseLeave = () => setActive(false);

	const image = isActive && figure.hover ? figure.hover : figure.normal;

	const renderImage = () => {
		if (typeof image === "string") return <img src={image} alt={data.name} />;
		const Svg = image;
		return <Svg />;
	};

	const props = showInfo ? { onMouseEnter, onMouseLeave } : {};

	return (
		<div className={classNames(styles.figure, className)} {...props}>
			{renderImage()}
			{isActive && data && <InfoBox data={data} />}
		</div>
	);
};

Minifigure.propTypes = {
	figure: PropTypes.object,
	data: PropTypes.object,
	className: PropTypes.string,
	showInfo: PropTypes.bool,
};
Minifigure.defaultProps = {
	figure: Figures.Manni,
	data: {
		name: "Manni",
	},
	showInfo: false,
};

export default Minifigure;
