import React from "react";
import classNames from "classnames";

import Modal from "components/Modal";

import styles from "./Overlay.module.scss";

export const BgBlurLevel = {
	None: "blurNone",
	Normal: "blurNormal",
	Strong: "blurStrong",
};

export const Overlay = ({ bgBlur = BgBlurLevel.Normal, children, onBgClick }) => (
	<Modal>
		<div className={classNames(styles.background, styles[bgBlur])} onClick={onBgClick} />
		{children}
	</Modal>
);

export default Overlay;
