import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import appRoutes from "constants/appRoutes";
import { UsersContext } from "contexts/Users";
import { loadUsers } from "services/apiLoaders";
import Input from "components/Form/Input";
import Section from "components/Section";
import Loading from "components/Loading";
import Button from "components/Button";
import { showNotification, NotificationType } from "components/Notification";
import { IconSearch, IconCrown, IconPending, IconClose } from "components/Icon";
import Minifigure, { Figures } from "components/Minifigure";

import UserTile from "./components/UserTile";
import styles from "./Overview.module.scss";
import Hint from "components/Hint";

const sortUsers = (userA, userB) => {
	userA = userA.firstName.toUpperCase();
	userB = userB.firstName.toUpperCase();

	let comparison = 0;
	if (userA > userB) {
		comparison = 1;
	} else if (userA < userB) {
		comparison = -1;
	}
	return comparison;
};

const Overview = () => {
	const history = useHistory();
	const { users, setUsers } = useContext(UsersContext);
	const [searchValue, setSearchValue] = useState("");
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [showHint, setHintVisibility] = useState(
		localStorage.getItem("showHint__user_overview")
			? parseInt(localStorage.getItem("showHint__user_overview"), 10) === 1
			: true,
	);

	// load users
	useEffect(() => {
		(async () => {
			setLoadingUsers(true);
			try {
				const userResponse = await loadUsers();
				setUsers(userResponse.data);
			} catch (error) {
				showNotification({
					message: "User konnten nicht geladen werden",
					type: NotificationType.Error,
				});
			}
			setLoadingUsers(false);
		})();
	}, [setUsers]);

	const onChangeSearch = event => setSearchValue(event.target.value || "");
	const onClickAddUser = () => history.push(appRoutes.createUser());
	const onClickCloseHint = () => {
		localStorage.setItem("showHint__user_overview", 0);
		setHintVisibility(false);
	};

	const userTiles = () => {
		let filteredUsers;
		const searchValueLower = searchValue.toLowerCase();
		if (searchValue) {
			filteredUsers = users.filter(
				user =>
					user.fullName.toLowerCase().includes(searchValueLower) ||
					user.email.toLowerCase().includes(searchValueLower),
			);
		} else {
			filteredUsers = users;
		}

		return filteredUsers
			.sort(sortUsers)
			.map(user => <UserTile key={user.id} className={styles.tile} user={user} />);
	};

	return (
		<Section className={styles.section}>
			<Section.Header>
				<h1>Freundesübersicht</h1>
			</Section.Header>
			<Section.Navbar>
				<Input
					name="search"
					placeholder="Freund suchen ..."
					value={searchValue}
					onChange={onChangeSearch}
					className={styles.searchInput}
					autoComplete="off"
					icon={IconSearch}
				/>
				<Button.Primary onClick={onClickAddUser}>Freund hinzufügen</Button.Primary>
			</Section.Navbar>

			{showHint && (
				<Section.Inner className={classNames(styles.inner, styles.hintWrapper)}>
					<Hint>
						<h2>Was ist das hier eigentlich?</h2>
						<div className={styles.legend}>
							<div>
								<Minifigure figure={Figures.Male} className={styles.minifigure} />
								<IconCrown className={styles.iconCrown} />
								<label>Ultra-Admin</label>
							</div>
							<div>
								<Minifigure figure={Figures.Male} className={styles.minifigure} />
								<label>Guter Freund</label>
							</div>
							<div>
								<Minifigure
									figure={Figures.Male}
									className={classNames(styles.minifigure, styles.pending)}
								/>
								<IconPending className={styles.iconPending} />
								<label>Muss noch die Einladung bestätigen</label>
							</div>
							<div>
								<Minifigure
									figure={Figures.Male}
									className={classNames(styles.minifigure, styles.inactive)}
								/>
								<label>Inaktiver Freund</label>
							</div>
						</div>
					</Hint>
					<Button.Icon icon={IconClose} onClick={onClickCloseHint} className={styles.btnCloseHint} />
				</Section.Inner>
			)}

			<Section.Inner className={styles.inner}>
				{loadingUsers ? <Loading /> : <div className={styles.userTilesContainer}>{userTiles()}</div>}
			</Section.Inner>
		</Section>
	);
};

export default Overview;
