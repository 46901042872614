import React from "react";
import { Switch } from "react-router-dom";

import AppRoutes from "constants/appRoutes";
import ProtectedRoute from "components/ProtectedRoute";
import Create from "views/Admin/Users/Create";
import Edit from "views/Admin/Users/Edit";
import Overview from "views/Admin/Users/Overview";

const Users = () => {
	return (
		<Switch>
			<ProtectedRoute exact path={AppRoutes.adminUsers()} component={Overview} />
			<ProtectedRoute exact path={AppRoutes.editUser(":userId")} component={Edit} />
			<ProtectedRoute exact path={AppRoutes.createUser()} component={Create} />
		</Switch>
	);
};

export default Users;
