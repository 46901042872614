import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Brick from "components/Brick";

import styles from "./FramedSection.module.scss";

const FramedSection = ({ width, height, color, className, children }) => (
	<div className={classNames(styles.framedSection, className)}>
		<Brick width={width} height={1} color={color} className={classNames(styles.brick, styles.top)} />
		<Brick width={1} height={height} color={color} className={classNames(styles.brick, styles.left)} />
		<Brick width={1} height={height} color={color} className={classNames(styles.brick, styles.right)} />
		<Brick width={width} height={1} color={color} className={classNames(styles.brick, styles.bottom)} />
		{children}
	</div>
);

FramedSection.propTypes = {
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	color: PropTypes.string.isRequired,
	className: PropTypes.string,
	children: PropTypes.node,
};

export default FramedSection;
