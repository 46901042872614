/* CAUTION, THIS FILE IS AUTO-GENERATED */
/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
export function IconArrorRight({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><g fill="none" fillRule="evenodd"><path fill="#000" d="M0 0h100v100H0z"/><path d="M29.453 1.385l48.056 47.44a1.652 1.652 0 010 2.35l-48.056 47.44a2 2 0 01-2.808.002l-3.2-3.15a2 2 0 01-.001-2.849L66.614 50 23.445 7.382a2 2 0 01.001-2.848l3.2-3.15a2 2 0 012.808.001z" fill="#FFF"/></g></svg>
}
IconArrorRight.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconArrowDown({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M98.615 29.453l-47.44 48.056a1.652 1.652 0 01-2.35 0L1.385 29.453a2 2 0 01-.002-2.808l3.15-3.2a2 2 0 012.849-.001L50 66.614l42.618-43.17a2 2 0 012.848.001l3.15 3.2a2 2 0 01-.001 2.808z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconArrowDown.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconArrowLeft({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M70.547 98.615l-48.056-47.44a1.652 1.652 0 010-2.35l48.056-47.44a2 2 0 012.808-.002l3.2 3.15a2 2 0 01.001 2.849L33.386 50l43.17 42.618a2 2 0 01-.001 2.848l-3.2 3.15a2 2 0 01-2.808-.001z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconArrowLeft.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconArrowRight({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M29.453 1.385l48.056 47.44a1.652 1.652 0 010 2.35l-48.056 47.44a2 2 0 01-2.808.002l-3.2-3.15a2 2 0 01-.001-2.849L66.614 50 23.445 7.382a2 2 0 01.001-2.848l3.2-3.15a2 2 0 012.808.001z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconArrowRight.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconArrowUp({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M1.385 70.547l47.44-48.056a1.652 1.652 0 012.35 0l47.44 48.056a2 2 0 01.002 2.808l-3.15 3.2a2 2 0 01-2.849.001L50 33.386 7.382 76.555a2 2 0 01-2.848-.001l-3.15-3.2a2 2 0 01.001-2.808z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconArrowUp.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconAvatar({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M73.52 91.667c.928 0 1.68.746 1.68 1.666v5c0 .92-.752 1.667-1.68 1.667H26.48c-.928 0-1.68-.746-1.68-1.667v-5c0-.92.752-1.666 1.68-1.666h47.04zM65.12 0C68.367 0 71 2.612 71 5.833v10.834h2.52C83.726 16.667 92 24.875 92 35v34.167C92 79.292 83.726 87.5 73.52 87.5H26.48C16.274 87.5 8 79.292 8 69.167V35c0-10.125 8.274-18.333 18.48-18.333H29V5.833C29 2.612 31.633 0 34.88 0h30.24zm-.862 61.45a2.534 2.534 0 00-3.556.239c-2.77 3.146-6.275 4.688-10.702 4.688s-7.932-1.542-10.702-4.688a2.534 2.534 0 00-3.556-.239 2.486 2.486 0 00-.24 3.528c3.738 4.246 8.633 6.4 14.498 6.4s10.76-2.154 14.498-6.4a2.486 2.486 0 00-.24-3.528zM37.4 41.667c-2.32 0-4.2 1.865-4.2 4.166 0 2.302 1.88 4.167 4.2 4.167 2.32 0 4.2-1.865 4.2-4.167 0-2.3-1.88-4.166-4.2-4.166zm25.2 0c-2.32 0-4.2 1.865-4.2 4.166 0 2.302 1.88 4.167 4.2 4.167 2.32 0 4.2-1.865 4.2-4.167 0-2.3-1.88-4.166-4.2-4.166z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconAvatar.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconCheck({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M2.793 48.36L.56 50.591a2.003 2.003 0 000 2.83l30.517 30.53a3.577 3.577 0 005.06 0l62.448-62.476c.78-.781.78-2.047 0-2.829l-2.232-2.232a1.999 1.999 0 00-2.827 0l-59.92 59.944-27.987-28a1.999 1.999 0 00-2.827 0z" fill="#FFF" fillRule="nonzero"/></svg>
}
IconCheck.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconClose({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M8.322 1.414L50 43.091 91.678 1.414a2 2 0 012.828 0l4.08 4.08a2 2 0 010 2.828L56.907 49.998l41.679 41.678a2 2 0 010 2.829l-4.08 4.079a2 2 0 01-2.828 0L50 56.906 8.322 98.584a2 2 0 01-2.829 0l-4.079-4.08a2 2 0 010-2.828l41.678-41.678L1.414 8.322a2 2 0 010-2.829l4.08-4.079a2 2 0 012.828 0z" fill="#FFF" fillRule="nonzero"/></svg>
}
IconClose.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconCrown({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M50 12a7 7 0 014.489 12.371l17.066 28.034 16.173-13.8a7 7 0 115.777 2.377L85.137 89H14.413L6.426 40.977a7 7 0 115.86-2.387l16.257 13.815 16.983-28.021A7 7 0 0150 12z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconCrown.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconDelete({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M83 24v74a2 2 0 01-2 2H19a2 2 0 01-2-2V24h66zM71 35h-6a1 1 0 00-1 1v52a1 1 0 001 1h6a1 1 0 001-1V36a1 1 0 00-1-1zm-36 0h-6a1 1 0 00-1 1v52a1 1 0 001 1h6a1 1 0 001-1V36a1 1 0 00-1-1zm18 0h-6a1 1 0 00-1 1v52a1 1 0 001 1h6a1 1 0 001-1V36a1 1 0 00-1-1zM63 0v7.999L87 8a1 1 0 011 1v8a1 1 0 01-1 1H14a1 1 0 01-1-1V9a1 1 0 011-1l22.999-.001L37 3V0h26zm-4 4H40.999v3.999H59V4z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconDelete.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconEdit({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M62.177 16.69l20.342 20.444-51.486 51.741-20.334-20.443 51.478-51.741zm35.778-4.93l-9.071-9.116a8.964 8.964 0 00-12.715 0l-8.693 8.729 20.342 20.443 10.137-10.187A6.976 6.976 0 00100 16.69a6.976 6.976 0 00-2.045-4.938v.007zM.055 97.17c-.173.783.067 1.6.636 2.162.569.563 1.385.79 2.161.604l22.678-5.523L5.195 73.97l-5.14 23.2z" fill="#FFF" fillRule="nonzero"/></svg>
}
IconEdit.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconHide({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M85.96 8.968a.999.999 0 01.006.007l4.685 4.73a1 1 0 010 1.407L15.455 91.025a1 1 0 01-1.42 0l-4.686-4.73a1 1 0 010-1.407L84.545 8.975a1 1 0 011.414-.007zm13.619 40.375l.42.656L100 50h-.001l-.42.657C85.649 72.219 69.105 83 49.946 83c-6.165 0-12.056-1.116-17.672-3.35l10.283-10.38A20.97 20.97 0 0050 70.625c11.506 0 20.833-9.234 20.833-20.625 0-2.739-.539-5.353-1.518-7.744l12.95-13.075c6.162 5.112 11.933 11.833 17.314 20.162zM49.946 17c6.117 0 11.967 1.099 17.55 3.297L57.239 30.654A20.978 20.978 0 0050 29.375c-11.506 0-20.833 9.234-20.833 20.625 0 2.662.509 5.206 1.436 7.541L17.55 70.721C11.289 65.486 5.439 58.58.001 50L0 50c13.946-22 30.594-33 49.946-33zM65 50c0 8.284-6.716 15-15 15-1.003 0-1.983-.098-2.93-.286L64.685 46.93c.206.99.314 2.018.314 3.07zM50 35c.92 0 1.82.083 2.694.241L35.267 52.833A15.08 15.08 0 0135 50c0-8.284 6.716-15 15-15z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconHide.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconInvitation({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M100 36v45a8 8 0 01-8 8H8a8 8 0 01-8-8V36l45.688 29.24a8 8 0 008.624 0L100 36zm-8.5-25a8.5 8.5 0 018.5 8.5c0 8.327-2.6 10.32-6.963 13.374L57.913 55.461a14 14 0 01-15.9.107L7.442 34.061C2.786 30.895 0 28.63 0 20a9 9 0 019-9h82.5z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconInvitation.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconLock({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M25.5 25l.003-.413C25.724 10.97 36.831 0 50.5 0c13.807 0 25 11.193 25 25v20.833H88V100H13V45.833h12.5V25zm41.667 20.833V25c0-9.205-7.462-16.667-16.667-16.667-9.205 0-16.667 7.462-16.667 16.667v20.833h33.334z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconLock.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconLogout({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M71.83 0a8 8 0 018 8v22.988h-8.854v-18.1a4 4 0 00-3.8-3.994l-.2-.005H21.854a4 4 0 00-3.995 3.8l-.005.2V87.11a4 4 0 003.8 3.995l.2.005h45.122a4 4 0 003.995-3.8l.005-.2V68.616h8.853V92a8 8 0 01-8 8H17a8 8 0 01-8-8V8a8 8 0 018-8h54.83zm.205 35.604a1.829 1.829 0 011.881.158l17.358 12.62c.459.333.73.86.73 1.42 0 .56-.271 1.086-.73 1.419L73.918 63.842a1.829 1.829 0 01-1.882.158c-.607-.3-.99-.91-.99-1.578V57.55H58.388c-.994 0-1.8-.795-1.8-1.77V43.825c0-.976.806-1.771 1.8-1.771h12.655v-4.874c0-.667.383-1.277.99-1.577z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconLogout.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconMenu({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M76.705 73c3.08 0 5.648 3.41 5.648 7.5S79.786 88 76.705 88H5.648C2.568 88 0 84.59 0 80.5S2.567 73 5.648 73h71.057zm16.437-30c3.74 0 6.858 3.41 6.858 7.5S96.883 58 93.142 58H6.858C3.118 58 0 54.59 0 50.5S3.117 43 6.858 43h86.284zm-11.87-31c3.263 0 5.983 3.41 5.983 7.5S84.535 27 81.27 27H5.984C2.72 27 0 23.59 0 19.5S2.72 12 5.984 12H81.27z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconMenu.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconPending({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M90 14c5.523 0 10 4.477 10 10v52c0 5.523-4.477 10-10 10H58l-8 12-8-12H10C4.477 86 0 81.523 0 76V24c0-5.523 4.477-10 10-10h80zM50 25c-13.807 0-25 11.193-25 25s11.193 25 25 25 25-11.193 25-25-11.193-25-25-25zm0 5.449a4 4 0 013.995 3.8l.005.2v13.596l10.457 8.139a4 4 0 01.83 5.436l-.13.177a4 4 0 01-5.437.83l-.177-.13-12-9.34a4 4 0 01-1.536-2.927L46 50V34.449a4 4 0 014-4z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconPending.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconProfile({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M50 54.794c23.428 0 42.759 18.465 44 42.027 0 4.527-19.697 2.89-44.004 2.89C25.688 99.712 6 101.233 6 96.822c1.241-23.562 20.572-42.027 44-42.027zM50.004 0c12.331 0 22.328 10.068 22.328 22.488s-9.997 22.487-22.328 22.487-22.328-10.068-22.328-22.487C27.676 10.068 37.673 0 50.004 0z" fill="#FFF" fillRule="nonzero"/></svg>
}
IconProfile.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconSearch({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M41.338 0C62.51 0 79.675 17.164 79.675 38.338c0 9.45-3.419 18.102-9.088 24.785l25.15 25.15a6.87 6.87 0 01-9.524 9.898l-.19-.183-26.107-26.107a38.165 38.165 0 01-18.578 4.794C20.164 76.675 3 59.511 3 38.338 3 17.164 20.164 0 41.338 0zm0 13.738c-13.586 0-24.6 11.014-24.6 24.6 0 13.586 11.014 24.6 24.6 24.6 13.586 0 24.6-11.014 24.6-24.6 0-13.586-11.014-24.6-24.6-24.6z" fill="#FFF" fillRule="nonzero"/></svg>
}
IconSearch.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconSelectArrow({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M11.59 24h76.82a5 5 0 013.636 8.431L53.637 73.145a5 5 0 01-7.274 0L7.953 32.43A5 5 0 0111.592 24z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconSelectArrow.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconShow({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M49.946 17c19.16 0 35.703 10.781 49.633 32.343l.42.656L100 50h-.001l-.42.657C85.649 72.219 69.105 83 49.946 83c-19.352 0-36-11-49.945-32.999L0 50c13.946-22 30.594-33 49.946-33zM50 29.375c-11.506 0-20.833 9.234-20.833 20.625 0 11.39 9.327 20.625 20.833 20.625 11.506 0 20.833-9.234 20.833-20.625 0-11.39-9.327-20.625-20.833-20.625zM50 35c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconShow.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
export function IconWarning({
  size = 16,
  className = '',
  ...props
}) {
  return <svg {...props} className={className} width={size} height={size} viewBox="0 0 100 100" ><path d="M54.759 8.35a9.18 9.18 0 013.046 3.07l40.857 67.563c2.629 4.346 1.265 10.019-3.046 12.67A9.086 9.086 0 0190.856 93H9.142C4.093 93 0 88.873 0 83.782c0-1.693.462-3.354 1.337-4.8L42.194 11.42c2.628-4.346 8.254-5.721 12.565-3.07zM50 68c-2.227 0-4.117.79-5.67 2.369C42.777 71.949 42 73.825 42 76c0 2.175.777 4.052 2.33 5.631C45.883 83.211 47.773 84 50 84s4.117-.79 5.67-2.369C57.223 80.051 58 78.175 58 76c0-2.175-.777-4.052-2.33-5.631C54.117 68.789 52.227 68 50 68zm8-40H42l2 33h12l2-33z" fill="#FFF" fillRule="evenodd"/></svg>
}
IconWarning.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};
