import React from "react";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";

import Notification, { NotificationType } from "./Notification";
import { noop } from "constants/helpers";

export const showNotification = ({
	message,
	type = NotificationType.Default,
	autoCloseDelay = 4500,
	onClose = noop,
}) => {
	toaster.notify(
		({ onClose: close }) => (
			<Notification
				type={type}
				onClose={() => {
					close();
					onClose();
				}}
			>
				{message}
			</Notification>
		),
		{ duration: autoCloseDelay > -1 ? autoCloseDelay : null, position: "top-right" },
	);
};
