import React from "react";
import { Switch } from "react-router-dom";
import ProtectedRoute from "components/ProtectedRoute";

import Users from "views/Admin/Users";
import WeeklyReport from "views/Admin/WeeklyReport";
import AppRoutes from "constants/appRoutes";

const Admin = () => {
	return (
		<Switch>
			<ProtectedRoute path={AppRoutes.adminUsers()} component={Users} />
			<ProtectedRoute exact path={AppRoutes.adminReports(":date")} component={WeeklyReport} />
		</Switch>
	);
};

Admin.propTypes = {};

export default Admin;
