import React, { useContext, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
import { useHistory, useParams } from "react-router-dom";
import dayJsIso from "dayJsIso";

import VoteBoard from "components/VoteBoard";
import Loading from "components/Loading";
import Section from "components/Section";
import Button from "components/Button";
import { IconArrowLeft, IconArrowRight } from "components/Icon";
import Hint from "components/Hint";
import { showNotification, NotificationType } from "components/Notification";
import { loadInitialVote, loadTeamVotes, loadTopics } from "services/apiLoaders";
import { formatDate, getWeekDaysFromDate } from "services/dateService";
import CONFIG from "constants/config";
import appRoutes from "constants/appRoutes";
import { errors } from "constants/texts";
import AppContext from "contexts/AppContext/context";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./WeeklyReport.module.scss";

let previousWeekdates = null;

const WeeklyReport = () => {
	const [earliestVoteDate, setEarliestVoteDate] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showNumbers, setNumbersVisibility] = useState(true);
	const [noDataError, setNoDataError] = useState();
	const { topics, teamVotes, updateTopics, updateTeamVotes } = useContext(AppContext);
	const history = useHistory();
	const { date } = useParams();

	const weekDates = useMemo(() => {
		if (!previousWeekdates || !previousWeekdates.includes(date)) {
			return (previousWeekdates = getWeekDaysFromDate(date));
		}
		return previousWeekdates;
	}, [date]);

	// load topics
	useEffect(() => {
		const loadData = async () => {
			try {
				const topicsResponse = await loadTopics();
				updateTopics(topicsResponse.data);
			} catch (error) {
				console.warn(error);
				showNotification({
					message: errors.defaultErrorMessage(),
					type: NotificationType.Error,
				});
			}
			setLoading(false);
		};

		if (!topics.length) {
			loadData();
		}
	}, [topics, updateTopics]);

	// load earliest vote and set available years
	useEffect(() => {
		loadInitialVote()
			.then(response => {
				setEarliestVoteDate(response.data.date);
			})
			.catch(error => {
				console.warn(error);
				showNotification({
					message: errors.defaultErrorMessage(),
					type: NotificationType.Error,
				});
			});
		setLoading(false);
	}, []);

	// load team votes
	useEffect(() => {
		const loadData = async () => {
			setLoading(true);
			try {
				const teamVotesResponse = await loadTeamVotes(weekDates[0]);
				updateTeamVotes(teamVotesResponse.data);
				setNoDataError();
			} catch (error) {
				console.warn(error);
				updateTeamVotes([]);
				if (error.response.status === 422 && error.response.data && error.response.data.errors.startDate) {
					setNoDataError(errors.votes.noVotesAvailable());
				} else {
					showNotification({
						message: errors.defaultErrorMessage(),
						type: NotificationType.Error,
					});
				}
			}
			setLoading(false);
		};

		if (weekDates.length) {
			loadData();
		} else {
			setLoading(false);
		}
	}, [updateTeamVotes, weekDates]);

	const handleDatepickerChange = newDate =>
		history.push(appRoutes.adminReports(formatDate(newDate, CONFIG.DefaultDateFormat)));

	const handleSkipWeekClick = value => {
		let newDate;
		if (value === 1) {
			newDate = dayJsIso(date).add(1, "week");
		} else {
			newDate = dayJsIso(date).subtract(1, "week");
		}
		history.push(appRoutes.adminReports(formatDate(newDate, CONFIG.DefaultDateFormat)));
	};

	const toggleNumbers = () => setNumbersVisibility(!showNumbers);

	return (
		<Section className={styles.weeklyReports}>
			<Section.Header>
				<h1>Wochenauswertung</h1>
			</Section.Header>
			<Section.Navbar>
				<div className={styles.navbarLeft}>
					<Button.Primary
						disabled={loading}
						icon={IconArrowLeft}
						onClick={() => {
							handleSkipWeekClick(-1);
						}}
					>
						Woche zurück
					</Button.Primary>
					<DatePicker
						selected={new Date(date)}
						onChange={handleDatepickerChange}
						dateFormat="dd.MM.yyyy"
						minDate={new Date(earliestVoteDate)}
						maxDate={new Date()}
						locale={de}
						className={styles.datepicker}
						disabled={loading}
					/>
					<Button.Primary
						disabled={loading}
						className={styles.btnNext}
						icon={IconArrowRight}
						onClick={() => {
							handleSkipWeekClick(1);
						}}
					>
						Woche vor
					</Button.Primary>
				</div>
				<div className={styles.navbarRight}>
					{loading && <Loading className={styles.loading} />}
					{!noDataError && (
						<Button.Primary onClick={toggleNumbers}>
							{showNumbers ? `Bricks anzeigen` : `Werte anzeigen`}
						</Button.Primary>
					)}
				</div>
			</Section.Navbar>
			<Section.Inner>
				{noDataError ? (
					<div className={styles.noDataError}>
						<Hint className={styles.hint}>Für den gewählten Zeitraum liegen noch keine Daten vor.</Hint>
					</div>
				) : (
					<VoteBoard
						weekDates={weekDates}
						topics={topics}
						teamVotes={teamVotes}
						isEditable={false}
						showTotals={showNumbers}
					/>
				)}
			</Section.Inner>
		</Section>
	);
};

export default WeeklyReport;
