import axios from "axios";
import ApiRoutes from "constants/apiRoutes";
import { getRefreshToken, setTokens, deleteTokens } from "services/tokenService";
import { refreshToken } from "services/apiLoaders";
const axiosApi = axios.create({
	baseURL: ApiRoutes.baseUrl,
	headers: { Accept: "application/json" },
});

axiosApi.interceptors.response.use(
	response => {
		return response;
	},
	function (error) {
		const originalRequest = error.config;
		if (
			error.response.status === 401 &&
			originalRequest.url !== ApiRoutes.refreshToken &&
			!originalRequest._retry
		) {
			originalRequest._retry = true;
			return refreshToken(getRefreshToken())
				.then(response => {
					if (response.status === 200) {
						const newTokens = {
							access_token: response.data.access_token,
							refresh_token: response.data.refresh_token,
						};
						setTokens(newTokens);
						originalRequest.headers.Authorization = "Bearer " + newTokens.access_token;
						return axiosApi(originalRequest);
					}
					throw new Error("Refresh failed");
				})
				.catch(error => {
					deleteTokens();
					window.location.href = ApiRoutes.login;
				});
		}
		// return Error object with Promise
		return Promise.reject(error);
	},
);

export default axiosApi;
