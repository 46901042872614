import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { AuthContext } from "contexts/AuthContext";
import { UsersContext } from "contexts/Users";
import Button from "components/Button";
import Loading from "components/Loading";
import Section from "components/Section";
import { showNotification, NotificationType } from "components/Notification";
import { createUserInvitation, deleteUserInvitation, getUser, updateUser as apiUpdateUser } from "services/apiLoaders";
import appRoutes from "constants/appRoutes";
import { APP_URL } from "constants/env";
import { IconArrowLeft, IconDelete } from "components/Icon";

import UserForm from "../components/UserForm";

import styles from "../Users.module.scss";
import CopyLink from "components/CopyLink";

const getUserInvitationUrl = token => APP_URL + appRoutes.setPassword(token);

const Edit = () => {
	const history = useHistory();
	const { userId } = useParams();
	const { updateUser } = useContext(UsersContext);
	const { user: authenticatedUser } = useContext(AuthContext);
	const [userData, setUserData] = useState();
	const [submitting, setSubmitting] = useState();
	const [serverValidationErrors, setServerValidationErrors] = useState();

	const handleFormSubmit = formData => {
		setSubmitting(true);
		apiUpdateUser(formData)
			.then(response => {
				setUserData(response.data);
				updateUser(response.data);

				showNotification({
					message: "User erfolgreich aktualisiert",
					type: NotificationType.Success,
				});

				history.push(appRoutes.adminUsers());
			})
			.catch(error => {
				if (error.response.status === 422 && error.response.data && error.response.data.errors.email) {
					setServerValidationErrors([
						{ type: "unique", name: "email", message: "Diese E-Mail Adresse ist bereits in Benutzung." },
					]);
				} else {
					showNotification({
						message: "User konnte nicht aktualisiert werden",
						type: NotificationType.Error,
					});
				}
			});
		setSubmitting(false);
	};

	const onCreateInvitationClick = useCallback(async userId => {
		try {
			const response = await createUserInvitation(userId);
			setUserData(prevState => ({ ...prevState, invitationToken: response.data.invitationToken }));
		} catch (error) {
			console.warn(error);
			showNotification({
				message: "Fehler beim erstellen",
				type: NotificationType.Error,
			});
		}
	}, []);

	const userInvitationTypeText = useMemo(() => {
		if (userData && userData.isConfirmed) {
			return "Password-Reset";
		}
		return "Einladung";
	}, [userData]);

	const onDeleteInvitationClick = useCallback(async userId => {
		try {
			await deleteUserInvitation(userId);
			setUserData(prevState => ({ ...prevState, invitationToken: null }));
		} catch (error) {
			console.warn(error);
			showNotification({
				message: "error on invitation delete",
				type: NotificationType.Error,
			});
		}
	}, []);

	// initial user data loading
	useEffect(() => {
		const loadUser = async userId => {
			try {
				const response = await getUser(userId);
				setUserData(response.data);
			} catch (error) {
				showNotification({
					message: "User nicht gefunden",
					type: NotificationType.Error,
				});
			}
		};

		if (userId && !userData) {
			loadUser(userId);
		}
	}, [userId, userData, setUserData]);

	return (
		<Section>
			<Section.Header className={styles.header}>
				<div>
					<Button.Icon icon={IconArrowLeft} to={appRoutes.adminUsers()} className={styles.btnBack} />
					<h1>Freund bearbeiten</h1>
				</div>
			</Section.Header>
			<Section.Inner className={styles.inner}>
				{!userData ? (
					<Loading />
				) : (
					<>
						<div className={styles.blocks}>
							<div>
								<h2>Profildaten</h2>
								<UserForm
									handleFormSubmit={handleFormSubmit}
									userData={userData}
									serverValidationErrors={serverValidationErrors}
								/>
							</div>
							{userData.id !== authenticatedUser.id && (
								<div>
									<h2>{userInvitationTypeText}</h2>
									{userData.invitationToken ? (
										<>
											<CopyLink link={getUserInvitationUrl(userData.invitationToken)} />

											<Button.Secondary
												onClick={() => onDeleteInvitationClick(userId)}
												icon={IconDelete}
											>
												{userInvitationTypeText} löschen
											</Button.Secondary>
										</>
									) : (
										<>
											<Button.Primary onClick={() => onCreateInvitationClick(userId)}>
												{userInvitationTypeText} erstellen
											</Button.Primary>
										</>
									)}
								</div>
							)}
						</div>
					</>
				)}
				{submitting && <Loading layered />}
			</Section.Inner>
		</Section>
	);
};

Edit.propTypes = {};

export default Edit;
