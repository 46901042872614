import React, { useState, useCallback } from "react";

import { UsersContext } from "./UsersContext";

export const UsersContextProvider = ({ children }) => {
	const [users, setUsers] = useState([]);

	const addUser = useCallback(user => setUsers(prevState => [...prevState, user]), []);
	const getUserById = useCallback(userId => users.find(user => user.id === userId), [users]);
	const updateUser = useCallback(
		updatedUserData =>
			setUsers(prevState =>
				prevState.map(prevStateUserData => {
					return prevStateUserData.id !== updatedUserData.id ? prevStateUserData : updatedUserData;
				}),
			),
		[],
	);

	return (
		<UsersContext.Provider
			value={{
				users,
				addUser,
				getUserById,
				updateUser,
				setUsers,
			}}
		>
			{children}
		</UsersContext.Provider>
	);
};
