import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import DraggableComponent from "components/Draggable";
import RoundedBrick from "components/RoundedBrick";
import Hint from "components/Hint";
import AppContext from "contexts/AppContext/context";
import CONFIG from "constants/config";
import { BRICK, WEEKDAY } from "namespaces";
import { getCurrentDate } from "services/dateService";

import styles from "./DraggableBricks.module.scss";

const CurrentDay = getCurrentDate(null, CONFIG.DefaultDateFormat);

const DraggableBricks = ({ onDrop, bricksUsed, isLoading }) => {
	const { topics } = useContext(AppContext);

	const allBricksUsed = bricksUsed === topics.length;

	const renderBricks = () =>
		topics.map((data, i) => {
			if (i + 1 > bricksUsed) {
				return (
					<DraggableComponent
						key={i}
						className={styles.draggableBrick}
						name={`${BRICK}-${data.id}`}
						type={`${WEEKDAY}-${CurrentDay}`}
						onDropped={onDrop}
						topicId={data.id}
					>
						<RoundedBrick className={styles.roundedBrick} />
					</DraggableComponent>
				);
			}
			return null;
		});

	const renderNoBricks = () => <Hint className={styles.hint}>Danke!</Hint>;

	return (
		<div className={classNames(styles.bricksContainer, { [styles.allBricksUsed]: allBricksUsed })}>
			{isLoading || allBricksUsed ? renderNoBricks() : renderBricks()}
		</div>
	);
};

DraggableBricks.propTypes = {
	onDrop: PropTypes.func.isRequired,
	bricksUsed: PropTypes.number.isRequired,
	isLoading: PropTypes.bool,
};

DraggableBricks.defaultProps = {
	bricksUsed: 0,
	isLoading: true,
};

export default DraggableBricks;
