import React from "react";

import { DEBUG, VERSION } from "constants/env";
import Button from "components/Button";
import { IconLogout } from "components/Icon";
import appRoutes from "constants/appRoutes";
import { getStartOfWeekDate } from "services/dateService";
import Config from "constants/config";

import styles from "./Menu.module.scss";

const Menu = ({ close, handleLogout, handleNavItemClick }) => (
	<div className={styles.menu}>
		<div>
			<div className={styles.buttons}>
				<Button.Simple onClick={() => handleNavItemClick(appRoutes.home(), close)}>Jetzt voten</Button.Simple>
				<Button.Simple
					onClick={() =>
						handleNavItemClick(appRoutes.adminReports(getStartOfWeekDate(null, Config.DefaultDateFormat)), close)
					}
				>
					AUSWERTUNGEN
				</Button.Simple>
				<Button.Simple onClick={() => handleNavItemClick(appRoutes.adminUsers(), close)}>
					Freunde verwalten
				</Button.Simple>
			</div>
		</div>
		<div className={styles.version}>
			<div className={styles.buttons}>
				{DEBUG && (
					<Button.Secondary onClick={() => handleNavItemClick(appRoutes.sandbox(), close)}>Sandbox</Button.Secondary>
				)}
				<Button.Primary onClick={handleLogout} icon={IconLogout}>
					Logout
				</Button.Primary>
			</div>
			Version: {VERSION}
		</div>
	</div>
);

export default Menu;
