import React from "react";

import Section from "components/Section";
import appRoutes from "constants/appRoutes";
import Button from "components/Button";
import Title from "components/Title";
import Hint from "components/Hint";

import styles from "./Success.module.scss";

const Success = () => {
	return (
		<Section className={styles.wrapper}>
			<Section.Inner className={styles.inner}>
				<Title className={styles.title} />

				<div className={styles.box}>
					<Hint>Dein Passwort wurde erfolgreich gespeichert</Hint>
					<Button.Primary to={appRoutes.login()} className={styles.btnLogin}>
						Jetzt einloggen
					</Button.Primary>
				</div>
			</Section.Inner>
		</Section>
	);
};

export default Success;
