import axiosApi from "axiosApi";
import ApiRoutes from "constants/apiRoutes";

const transformBooleansForRequest = (data, booleanProperties = []) => {
	booleanProperties.forEach(propertyName => (data[propertyName] = data[propertyName] === true ? 1 : 0));
	return data;
};

export const loadTopics = () => axiosApi.get(ApiRoutes.getAllTopics);

export const loadTeamVotes = startDate => axiosApi.get(ApiRoutes.getVotesByDate, { params: { startDate: startDate } });

export const loadUserVotes = date => axiosApi.get(ApiRoutes.getUserVotesByDay, { params: { date: date } });

export const loadInitialVote = () => axiosApi.get(ApiRoutes.getInitialVote);

export const createVote = (topicId, approval) =>
	axiosApi.post(ApiRoutes.createVote, { topicId: topicId, approval: approval });

export const deleteVote = voteId => axiosApi.delete(ApiRoutes.deleteVote + `/${voteId}`);

export const loadUsers = () => axiosApi.get(ApiRoutes.getUsers);

export const createUser = userData =>
	axiosApi.post(ApiRoutes.createUser, transformBooleansForRequest(userData, ["isAdmin", "isActive"]));

export const getUser = userId => axiosApi.get(ApiRoutes.getUser + `/${userId}`);

export const setPassword = requestData => axiosApi.post(ApiRoutes.setPassword, requestData);

export const updateUser = userData =>
	axiosApi.post(
		ApiRoutes.updateUser + `/${userData.id}`,
		transformBooleansForRequest(userData, ["isAdmin", "isActive"]),
	);

export const createUserInvitation = userId => axiosApi.post(ApiRoutes.createInvitation(userId));

export const deleteUserInvitation = userId => axiosApi.delete(ApiRoutes.deleteInvitation(userId));

export const validateInvitationToken = invitationToken =>
	axiosApi.post(ApiRoutes.validateInvitationToken, { token: invitationToken });

export const refreshToken = refreshToken => axiosApi.post(ApiRoutes.refreshToken, { refreshToken: refreshToken });
