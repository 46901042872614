import React from "react";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";

import { AuthContextProvider } from "contexts/AuthContext";
import { DEBUG } from "constants/env";
import WIP from "components/WIP";
import { getAccessToken } from "services/tokenService";
import axiosApi from "axiosApi";
import AppRouter from "./AppRouter";

const App = () => {
	const accessToken = getAccessToken();
	axiosApi.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

	return (
		<>
			<DndProvider backend={Backend}>
				<AuthContextProvider accessToken={accessToken}>
					<AppRouter />
				</AuthContextProvider>
			</DndProvider>
			{!DEBUG && <WIP />}
		</>
	);
};

export default App;
