import PropTypes from "prop-types";

export const topic = {
	id: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired
};

export const vote = {
	id: PropTypes.number.isRequired,
	topicId: PropTypes.number.isRequired,
	userId: PropTypes.number,
	date: PropTypes.string.isRequired,
	approval: PropTypes.bool.isRequired,
};

export const user = {
	id: PropTypes.number.isRequired,
	fullName: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	isActive: PropTypes.bool.isRequired,
	isConfirmed: PropTypes.bool.isRequired,
	invitationToken: PropTypes.string,
};
