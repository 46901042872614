import { user } from "constants/propShapes";
import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useClipboard } from "use-clipboard-copy";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import Tile from "components/Tile";
import { IconEdit, IconInvitation, IconCrown, IconPending } from "components/Icon";
import Minifigure, { Figures } from "components/Minifigure";
import { showNotification, NotificationType } from "components/Notification";
import appRoutes from "constants/appRoutes";
import { APP_URL } from "constants/env";

import styles from "./UserTile.module.scss";

const UserTile = ({ className, user }) => {
	const history = useHistory();
	const { id, fullName, isAdmin, isActive, isConfirmed, invitationToken } = user;

	const pendingInvitation = invitationToken && !isConfirmed;

	const clipboard = useClipboard({ copiedTimeout: 2000 });
	const copyToClipboard = token => clipboard.copy(APP_URL + appRoutes.setPassword(token));

	const onClickEdit = () => history.push(appRoutes.editUser(id));
	const onClickCopyInvitationLink = () => copyToClipboard(invitationToken);

	useEffect(() => {
		if (clipboard.copied) {
			showNotification({
				message: "Link wurde in die Zwischenablage kopiert",
				type: NotificationType.Success,
			});
		}
	}, [clipboard]);

	return (
		<Tile
			className={classNames(styles.userTile, className, {
				[styles.inActive]: !isActive,
				[styles.pendingInvitation]: pendingInvitation,
				[styles.isAdmin]: isAdmin,
			})}
		>
			<Minifigure figure={Figures.Male} className={styles.minifigure} />
			<div className={styles.name}>{fullName}</div>
			<div className={styles.buttons}>
				<Button.Icon icon={IconEdit} onClick={onClickEdit} />
				{pendingInvitation && <Button.Icon icon={IconInvitation} onClick={onClickCopyInvitationLink} />}
			</div>
			{isAdmin && <IconCrown className={styles.iconAdmin} />}
			{pendingInvitation && <IconPending className={styles.iconPendingInvitation} />}
		</Tile>
	);
};

UserTile.propTypes = {
	user: PropTypes.shape(user).isRequired,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default UserTile;
