import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import appRoutes from "constants/appRoutes";

function ProtectedRoute({ component: Component, ...rest }) {
	const { authToken } = useContext(AuthContext);
	if (!authToken) {
		return <Redirect to={appRoutes.login()} />;
	}
	return <Route {...rest} render={props => <Component {...props} />} />;
}

export default ProtectedRoute;
