import React, { useContext } from "react";

import AppContext from "contexts/AppContext/context";
import Button from "components/Button";

import styles from "./DebugTools.module.scss";

const DebugTools = () => {
	const { updateTeamVotes } = useContext(AppContext);

	const voting = data => {
		const { index } = data;
		return {
			object: "Voting",
			id: 100000 + index,
			topicId: 1,
			userId: index,
			date: new Date(),
			approval: true,
			...data,
		};
	};
	const fillDay = ({ amount, date = new Date() }) =>
		Array.from({ length: amount }, (vote, index) => voting({ index, date }));

	const onClickFillVotes = () => {
		const votes = fillDay({ amount: 20 });

		updateTeamVotes(votes);
	};

	return (
		<div className={styles.debugTools}>
			<Button.Primary onClick={onClickFillVotes}>Fill votes</Button.Primary>
		</div>
	);
};

export default DebugTools;
