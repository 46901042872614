import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import appRoutes from "constants/appRoutes";
import logoCheckout from "assets/images/logo-checkout.png";

import styles from "./Title.module.scss";

const Title = ({ className }) => (
	<div className={classNames(styles.title, className)}>
		<Link to={appRoutes.home()}>
			<img src={logoCheckout} className={styles.logoCheckout} alt="" />
		</Link>
	</div>
);

export default Title;
