import CONFIG from "constants/config";
import dayJsIso from "dayJsIso";
import { DEBUG_DATE } from "constants/env";

const sanitizeInput = date => {
	if (!date) {
		return DEBUG_DATE ? dayJsIso(DEBUG_DATE) : dayJsIso();
	}

	if (!(date instanceof dayJsIso)) {
		return dayJsIso(date);
	}

	return date;
};

export const getCurrentDate = (date, format) => {
	date = sanitizeInput(date);

	if (format) {
		return date.format(format);
	}
	return date;
};

export const getWeekFromDate = (date = null) => {
	return sanitizeInput(date).isoWeek();
};

export const getDayFromDate = (date = null) => {
	return sanitizeInput(date).date();
};

export const getMonthFromDate = (date = null) => {
	return sanitizeInput(date).month() + 1;
};

export const getYearFromDate = (date = null) => {
	return sanitizeInput(date).year();
};

export const getFirstDayFromWeek = (weekOfYear, year = null) => {
	year = year ? year.toString() : getYearFromDate().toString();

	return dayJsIso(year).isoWeek(weekOfYear).startOf("isoWeek");
};

export const getStartOfWeekDate = (date = null, format = null) => {
	const startOfWeek = sanitizeInput(date).startOf("isoWeek");

	if (format) {
		return startOfWeek.format(format);
	}
	return startOfWeek;
};

export const getEndOfWeekDate = (date = null, format = null) => {
	const endOfWeek = sanitizeInput(date).endOf("isoWeek");

	if (format) {
		return endOfWeek.format(format);
	}
	return endOfWeek;
};

export const getNumberOfWeeksFromYear = (year = null) => {
	return sanitizeInput(year).isoWeeksInYear();
};

export const getWeekDaysFromDate = date => {
	const startOfWeek = getStartOfWeekDate(sanitizeInput(date));
	return [...Array(5)].map((value, index) => {
		return startOfWeek.add(index, "days").format(CONFIG.DefaultDateFormat);
	});
};

export const formatDate = (date, format) => {
	return sanitizeInput(date).format(format);
};

export default {
	formatDate,
	getWeekFromDate,
	getFirstDayFromWeek,
	getStartOfWeekDate,
	getEndOfWeekDate,
};
