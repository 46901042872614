import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./SuccessMessage.module.scss";

const SuccessMessage = ({ children, className }) => (
	<div className={classNames(styles.successMsg, className)}>{children}</div>
);

SuccessMessage.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

export default SuccessMessage;
