import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ReactComponent as LoadingBricksSVG } from "assets/icons/loading.svg";
import styles from "./Loading.module.scss";

const Loading = ({ className, layered }) => {
	const loadingBricks = <LoadingBricksSVG className={classNames(styles.loadingSpinner, className)} />;
	if (layered) {
		return <div className={styles.loadingLayeredWrapper}>{loadingBricks}</div>;
	}
	return loadingBricks;
};

Loading.propTypes = {
	layered: PropTypes.bool,
	className: PropTypes.string,
};

Loading.defaultProps = {
	layered: false,
};

export default Loading;
