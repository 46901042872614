import React from "react";

import Minifigure, { Figures } from "components/Minifigure";
import Section from "components/Section";
import { ReactComponent as Logo } from "assets/images/logo.svg";

import styles from "./Footer.module.scss";

const Footer = () => (
	<Section className={styles.footer}>
		<Section.Inner className={styles.inner}>
			<Logo className={styles.logo} />
			<div>
				<Minifigure
					figure={Figures.Paul}
					data={{
						name: "Paul Sorge",
						email: "paul.sorge@me.com",
					}}
					className={styles.minifigure}
					showInfo={true}
				/>
				<Minifigure
					figure={Figures.Stefan}
					data={{
						name: "Stefan Knop",
						www: "https://www.stefanknop.de",
					}}
					className={styles.minifigure}
					showInfo={true}
				/>
			</div>
		</Section.Inner>
	</Section>
);

export default Footer;
