import React, { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router";

import { welcomeUser } from "constants/texts";
import { AuthContext } from "contexts/AuthContext";
import Section from "components/Section";
import Button from "components/Button";
import { IconMenu, IconLogout } from "components/Icon";
import { OverlaySlideIn, SlideInDirection } from "components/Overlay";
import Menu from "components/Menu";
import { IconAvatar } from "components/Icon";
import Title from "components/Title";

import styles from "./Header.module.scss";

const Header = () => {
	const history = useHistory();
	const { user, signOut } = useContext(AuthContext);
	const [menuIsVisible, setMenuVisibility] = useState(false);

	const onClickBtnMenu = () => setMenuVisibility(true);
	const onCLickMenuLink = useCallback(
		(to, closeMenu) => {
			closeMenu();
			history.push(to);
		},
		[history],
	);

	const handleLogout = event => {
		event.preventDefault();
		setMenuVisibility(false);
		signOut();
	};

	const renderUserDisplayName = () => {
		// @TODO: first name und last name in database
		if (!user) return null;
		const username = user.fullName.split(" ");
		return username[0];
	};

	return (
		<Section className={styles.header}>
			<Section.Inner>
				<Title />
				{user && (
					<nav className={styles.nav}>
						<div className={styles.name}>
							<IconAvatar className={styles.avatar} />
							<div className={styles.welcome}>{welcomeUser()}</div>
							<b>{renderUserDisplayName()}</b>
						</div>
						<Button.Icon onClick={handleLogout} icon={IconLogout} />
						{user.isAdmin && (
							<>
								<Button.Icon icon={IconMenu} onClick={onClickBtnMenu} />
								{menuIsVisible && (
									<OverlaySlideIn
										onSlideOut={() => setMenuVisibility(false)}
										direction={SlideInDirection.RightLeft}
									>
										{({ close }) => (
											<Menu
												close={close}
												handleLogout={handleLogout}
												handleNavItemClick={onCLickMenuLink}
											/>
										)}
									</OverlaySlideIn>
								)}
							</>
						)}
					</nav>
				)}
			</Section.Inner>
		</Section>
	);
};

export default Header;
