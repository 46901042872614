import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useClipboard } from "use-clipboard-copy";

import { UsersContext } from "contexts/Users";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";
import Section from "components/Section";
import { IconArrowLeft, IconAvatar } from "components/Icon";
import appRoutes from "constants/appRoutes";
import UserForm from "views/Admin/Users/components/UserForm";
import { APP_URL } from "constants/env";
import { createUser } from "services/apiLoaders";
import { showNotification, NotificationType } from "components/Notification";
import CopyLink from "components/CopyLink";

import styles from "../Users.module.scss";

const getSetPasswordLink = token => {
	return APP_URL + appRoutes.setPassword(token);
};

const Create = () => {
	const history = useHistory();
	const { addUser } = useContext(UsersContext);
	const [userData, setUserData] = useState();
	const [submitting, setSubmitting] = useState();
	const [submissionError, setSubmissionError] = useState();
	const [serverValidationErrors, setServerValidationErrors] = useState();
	const clipboard = useClipboard({
		copiedTimeout: 1000,
	});

	useEffect(() => {
		if (clipboard.copied) {
			showNotification({
				message: "Link wurde in die Zwischenablage kopiert",
				type: NotificationType.Success,
			});
		}
	}, [clipboard]);

	const handleFormSubmit = userData => {
		setSubmitting(true);
		setSubmissionError(null);
		createUser(userData)
			.then(response => {
				setUserData(response.data);
				addUser(response.data);
				setSubmitting(false);
			})
			.catch(error => {
				if (error.response.status === 422 && error.response.data && error.response.data.errors.email) {
					setServerValidationErrors([
						{ type: "unique", name: "email", message: "Diese E-Mail Adresse ist bereits in Benutzung." },
					]);
				} else {
					setSubmissionError("User konnte nicht aktualisiert werden.");
				}

				setSubmitting(false);
			});
	};

	const handleFormCancel = event => {
		event.preventDefault();
		history.push(appRoutes.adminUsers());
	};

	const resetForm = () => {
		setUserData(null);
		setServerValidationErrors(null);
	};

	return (
		<Section>
			<Section.Header className={styles.header}>
				<div>
					<Button.Icon icon={IconArrowLeft} to={appRoutes.adminUsers()} className={styles.btnBack} />
					<h1>Freund hinzufügen</h1>
				</div>
			</Section.Header>
			<Section.Inner className={styles.inner}>
				<div className={styles.blocks}>
					{userData ? (
						<div className={styles.success}>
							<IconAvatar className={styles.iconAvatar} />
							<h1>Klasse!</h1>
							<h2>"{userData.fullName}" wurde erfolgreich angelegt</h2>
							<div>
								Vor der ersten Anmeldung in diesem Tool, muss er mithilfe des folgenden Links und seiner
								E-Mail Addresse sein Passwort festlegen.
							</div>
							<CopyLink link={getSetPasswordLink(userData.invitationToken)} />

							<hr />

							<h2>Was möchtest Du als nächstes tun?</h2>
							<div className={styles.buttons}>
								<Button.Secondary to={appRoutes.adminUsers()}>Zurück zur Übersicht</Button.Secondary>
								<Button.Secondary onClick={resetForm}>Weiteren User anlegen</Button.Secondary>
							</div>
						</div>
					) : (
						<>
							<UserForm
								handleFormSubmit={handleFormSubmit}
								handleCancel={handleFormCancel}
								serverValidationErrors={serverValidationErrors}
							/>
							{submissionError && <ErrorMessage>{submissionError}</ErrorMessage>}
						</>
					)}
				</div>
				{submitting && <Loading layered />}
			</Section.Inner>
		</Section>
	);
};

export default Create;
