import React from "react";
import PropTypes from "prop-types";
import { useDrop } from "react-dnd";
import classNames from "classnames";

import { ReactComponent as DropableSVG } from "./assets/dropable.svg";

import styles from "./Dropable.module.scss";

const Dropable = ({ name, dropProps, dropAreaProps, className, children }) => {
	const [{ canDrop, isOver }, drop] = useDrop({
		drop: () => ({ name, ...dropAreaProps }),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		...dropProps,
	});
	const isActive = canDrop && isOver;

	return (
		<div
			ref={drop}
			className={classNames(styles.dropable, className, {
				[styles.isActive]: isActive,
				[styles.canDrop]: !isActive && canDrop,
			})}
		>
			{isActive || canDrop ? <DropableSVG className={styles.svg} /> : ""}
			{children && children}
		</div>
	);
};

Dropable.propTypes = {
	name: PropTypes.string.isRequired,
	dropProps: PropTypes.object,
	dropAreaProps: PropTypes.shape({
		topicId: PropTypes.number.isRequired,
		date: PropTypes.string.isRequired,
		approval: PropTypes.bool.isRequired,
	}).isRequired,
	className: PropTypes.string,
	children: PropTypes.node,
};

export default Dropable;
